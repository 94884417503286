
import React, { useState,useEffect } from 'react';
import swal from "sweetalert";
import SearchBar from '../../components/SearchBar';
import Datos from '../../services/Datos';
import {Quetzal, Dolar} from '../../utils/Moneda';
import urlImage from '../../services/urlImage';
import HeaderBar from '../Menu/HeaderBar';
import { useSelector } from 'react-redux';

//import { MapContainer, TileLayer, useMap,Marker,Popup } from 'react-leaflet'

function Cliente()  {
    const [idcliente, setIdCliente] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [telefono, setTelefono] = useState(""); 
    const [direccion, setDreccion] = useState("");
    const [dpi, setDpi] = useState("");
    const [fotoCliente, setFotoCliente] = useState(); 
    const [fotoDpiConCliente, setFotoDpiConCliente] = useState(); 
    const [fotoCasaCliente, setFotoCasaCliente] = useState();
    const [fotoDpiAdelante, setFotoDpiAdelante] = useState();
    const [fotoDpiAtras, setFotoDpiAtras] = useState();
    const [fotoReciboDeLuz, setFotoReciboDeLuz] = useState();
    const [ubicacion, setUbicacion] = useState(""); 
    const [estado, setEstado] = useState("Activo");
    const [latitudes, setLatitudes] =useState("");
    const [longitudes, setLongitudes] =useState("");
    const [linkMap, setLinkMap]=useState("");
    const [title, setTitle] = useState("")   
    const [datos, setdatos] = useState([]);  
    const [encontrado, setencontrado] = useState([]);
    const[clienteSelected,setClienteSelected]=useState([]);
    const [buscar, setbuscar] = useState("");
    const [accion, setAccion] = useState("new");
    const {idempleado} = useSelector(state=>state.user)


const[previewFotoCliente, setPreviewFotoCliente]=useState();
const[previewFotoCliencteConDPi, setPreviewFotoCliencteConDPi]=useState();
const [previewFotoDeCasa, setPreviewFotoDeCasa ]   =useState();
const [previewDpiAdelante, setPreviewDpiAdelante ]   =useState();
const [previewDpiAtras, setPreviewDpiAtras ]   =useState();
const [previewReciboDeLuz, setPreviewReciboDeLuz ]   =useState();
const[classTag, setClassTag]=useState("tag_copy")

    //const moneda=2;
    //const Moneda= moneda===1 ? Dolar: Quetzal;

    useEffect(()=>{
      ConsultarCliente();
      //getUbicacion();

    },[])
    
const getUbicacion=()=>{

navigator.permissions.query({name:'geolocation'}).then(function(result) {
  
  if (result.state == 'granted') {
    navigator.geolocation.getCurrentPosition(MostrarPosicion,MostraError);

    report(result.state);
//    geoBtn.style.display = 'none';
  } else if (result.state == 'prompt') {
    report(result.state);
  //  geoBtn.style.display = 'none';
    navigator.geolocation.getCurrentPosition(MostrarPosicion,MostraError);
  } else if (result.state == 'denied') {
    report(result.state);
    
    navigator.geolocation.getCurrentPosition(MostrarPosicion,MostraError);
    //geoBtn.style.display = 'inline';
  }
  result.addEventListener('change', function() {
    report(result.state);
  });
});
} 



function report(state) {
  
}
  const MostrarPosicion = (position) => { 
    setUbicacion(position.coords.latitude+","+position.coords.longitude);
   }
const MostraError = (error) => { 
switch(error.code){
case  error.PERMISSION_DENIED: 
swal("Aviso","No se puede obtener la ubicación, por favor habilite los permisos","warning");
break;
case error.POSITON_UNAVAILABLE:
swal("Aviso","La ubicacion no esta disponible","warnind");
break;
case error.TIMEOUT:
swal("Aviso","Se agoto el tiepo para obtener la ubicación","warning");
break;
case error.UNKNOWN_ERROR:
swal("Aviso","Hubo un error al intentar obtener la ubicacion","warning");
break;
default:
break;
}
 }
    const ConsultarCliente=async()=>{
      const datos=await Datos.Consulta("cliente");
    
      if(datos!==null){
       
        setdatos(datos.res);
        setencontrado(datos.res)
      }
    }
    
const SubirImagen =async (file) => { 
 const datosImg=await Datos.UploadImg(file);
 if(!datosImg ) {
 
    swal("Aviso","No se pudo cargar la imagen","error");
  
 }
}

const VerUbicacion =(item,e)=>{
  setClienteSelected(item);
  let ubica=item.ubicacion.split(",");
  setLatitudes(ubica[0]);
  setLongitudes(ubica[1]);
  setLinkMap(`https://www.google.es/maps?q=${ubica[0]},${ubica[1]}`);
  let myInput = document.getElementById("exampleDetalle");
  e.target.addEventListener("shown.bs.modal", function () {
    myInput.focus();
  });
}

const GetPreview = (file,setPreview) => { 
  let reader=new FileReader();
  reader.onloadend=function(e){
    setPreview(reader.result)
  }
  reader.readAsDataURL(file)
}
 
 
const BorarImagen = async (name) => { 
  const datosImg =await Datos.DeleteImg(name);
  if(!datosImg){
    swal("Aviso","No se pudo eliminar la imagen","warning");
  }
 }
    const limpiar=()=>{
      setIdCliente(0);
      setNombre("");
      setApellido("");
      setTelefono("");
      setDreccion("");
      setDpi("")
      setUbicacion("")
      setEstado("")
      setAccion("new");
    }

    const getClienteObject=(codigoCliente)=>{
    return {  
      idcliente:codigoCliente,
      nombre:nombre,
      apellido:apellido,
      telefono:telefono,
      direccion:direccion,
      dpi:dpi,
      f_perfil:"new",
      f_casa:"new",
      dpi_adelante:"new",
      ubicacion:ubicacion,
      estado:estado,
      dpi_atras:"new",
      cliente_con_dpi:"new",
      recibo_de_luz:"new",
      idempleado:idempleado
    }
    }

    const Ingresar=async()=>{
      let cliente=await Datos.NuevoReg("cliente",getClienteObject(0));
      if(cliente !== null){
        if(cliente.message==="Success"){
          swal("Cliente","Ingresdo exitosamente","success");
         limpiar();
          ConsultarCliente();
        }else{
          swal("Cliente","No se pudo Ingresar, verifique los datos","warning");
        }
      }
    }

    const AbrirIngresoImagen=(item,e)=>{
      
      setClienteSelected(item);
      setPreviewFotoCliente(urlImage(item.f_perfil));
      setPreviewFotoCliencteConDPi(urlImage(item.cliente_con_dpi));
      setPreviewFotoDeCasa(urlImage(item.f_casa));
      setPreviewDpiAdelante(urlImage(item.dpi_adelante));
      setPreviewDpiAtras(urlImage(item.dpi_atras));
      setPreviewReciboDeLuz(urlImage(item.recibo_de_luz));
      let myInput = document.getElementById("exampleFoto");
      e.target.addEventListener("shown.bs.modal", function () {
        myInput.focus();
      });
     }


                

                  
                 

    const Actualizar=async()=>{
      let cliente=await Datos.ActualizarReg("cliente",getClienteObject(idcliente));
      if(cliente !== null){
        if(cliente.message==="Success"){
          swal("Cliente","Ingresdo exitosamente","success");
          limpiar();
          ConsultarCliente();
        }else{
          swal("Cliente","No se pudo Ingresar, verifique los datos","warning");
        }
      }
    }

    
    
   



   

    const Eliminar=async(datos)=>{
      let cliente=await Datos.BorrarReg("cliente",datos.idcliente);
      if(cliente!==null){
        if(cliente.message === "Success"){
          swal("Cliente", "Eliminado con exíto","success")
          BorarImagen(datos.fotoCasaCliente);
          BorarImagen(datos.fotoDpiAdelante);
          BorarImagen(datos.fotoCliente);
          ConsultarCliente();

        }else{
          swal("Cliente","No se pudo eliminar","warning");
        }
      }
    }
    const GuardarCambios=(e)=>{
      e.preventDefault();
      if(accion==="new"){
        Ingresar();
      }else{
        Actualizar();
      }
    }
    const AbrirActualizar=(datos,e)=>{
      setTitle("Actualizar informaciòn del cliente")
      setClienteSelected(datos);
setIdCliente(datos.idcliente);
setNombre(datos.nombre);
setApellido(datos.apellido);
setTelefono(datos.telefono);
setDreccion(datos.direccion);
setDpi(datos.dpi)
setUbicacion(datos.ubicacion)
setEstado(datos.estado)
setAccion("update");

var myInput = document.getElementById("exampleModal");
    e.addEventListener("shown.bs.modal", function () {
      myInput.focus();
    });
    }

  

    const Busqueda =(e)=>{
      let buscarTexto=e.target.value;
      setbuscar(buscarTexto);
      let text=buscarTexto.replace(/^\w/,(c) =>c.toLowerCase());
      setbuscar(buscarTexto);
      
      setdatos(encontrado.filter((item)=>{
          return   item.nombre.toLowerCase().includes(text) ;   
        }).map(({idcliente, nombre, apellido, telefono, estado})=>{
          return{idcliente, nombre, apellido, telefono, estado}
        })
       );
      
        }
    
  const AbrirIngreso=(e)=>{
    setTitle("Ingreso de cliente")
    let myInput = document.getElementById("exampleModal");
    e.target.addEventListener("shown.bs.modal", function () {
      myInput.focus();
    });
    limpiar();
  }
const verEtiqueta = (visible) => { 
  if(visible){
    setClassTag("tag_copy tag_visible")
    setTimeout(()=>{
      setClassTag("tag_copy")
    },1000);

  }else{
setClassTag("tag_copy")
  }
 }

 const DeletePreviousImage=async(imageName)=>{
  const imageDeleted=await Datos.DeleteImg(imageName);
  if(!imageDeleted){
    swal("Plan","No se pudo eliminar  la imagen","warning");
  }
}
 const getCLienteObjectImg = (codigoCliente) => {
  return {
    idcliente:codigoCliente,
      nombre:nombre,
      apellido:apellido,
      telefono:telefono,
      direccion:direccion,
      dpi:dpi,
      f_perfil:fotoCliente.name,
      f_casa:fotoCasaCliente.name,
      dpi_adelante:fotoDpiAdelante.name,
      ubicacion:ubicacion,
      estado:estado,
      dpi_atras:fotoDpiAtras.name,
      cliente_con_dpi:fotoDpiConCliente.name,
      recibo_de_luz:fotoReciboDeLuz.name,
      idempleado:idempleado
}
} 

 const AgregarFotoCliente=async()=>{      
 
  let cliente=await Datos.ActualizarReg("clienteimg",getCLienteObjectImg(clienteSelected.idcliente));
  if(cliente !== null){
    if(cliente.message==="Success"){
      swal("Empleado","Ingresdo exitosamente","success");
     
    }else{
      swal("Empleado","No se pudo Ingresar, verifique los datos","warning");
    }
  }
}

 const GuardarCambiosFotos= (e)=>{
  e.preventDefault();
    try{            
      const ffrontal=fotoDpiAdelante;
      const ftrasera=fotoDpiAtras;
      const fperfil=fotoCliente;
      const fperfil2=fotoDpiConCliente;
      const fcasa=fotoCasaCliente;
      const fluz=fotoReciboDeLuz;
      

        AgregarFotoCliente();

        if(clienteSelected.dpi_adelante ==="new"){
          SubirImagen(ffrontal)
        }else{    
            SubirImagen(ffrontal)  
          DeletePreviousImage(clienteSelected.dpi_adelante);
        }

        if(clienteSelected.dpi_atras==="new"){
          SubirImagen(ftrasera)
        }else{
          SubirImagen(ftrasera)
          DeletePreviousImage(clienteSelected.dpi_atras);
        
  
        }
    
        if(clienteSelected.f_perfil==="new"){
          SubirImagen(fperfil)
        }else{
       
          DeletePreviousImage(clienteSelected.f_perfil);
          SubirImagen(fperfil)
       
        }

        if(clienteSelected.cliente_con_dpi==="new"){
          SubirImagen(fperfil2)
        }else{
    
          DeletePreviousImage(clienteSelected.cliente_con_dpi);
          SubirImagen(fperfil2)
    
        }
    
        if(clienteSelected.f_casa==="new"){
          SubirImagen(fcasa)
        }else{
       
          DeletePreviousImage(clienteSelected.f_casa);
          SubirImagen(fcasa)
     
        }
       
   
        if(clienteSelected.recibo_de_luz==="new"){
          SubirImagen(fluz)
        }else{
      
          DeletePreviousImage(clienteSelected.recibo_de_luz);
          SubirImagen(fluz)
  
        }
       

   
        ConsultarCliente()
        limpiar();
    }catch(error){
     
    }
  
}

    return(
        <div>
           <div className='div-header'>
            <HeaderBar  title="Cliente"/>
       
            <SearchBar
            onChange={Busqueda} 
            value={buscar} 
            placeholder="Buscar Cliente..."  

            data_bs_toggle="modal"
            data_bs_target="#exampleModal"
            onClick={AbrirIngreso}
            />
            </div>
               <div className='div-body'>
         <div className="div-table">
<div className="table-wrap">
  
<table className="table-item ">
  <thead >
          <tr>
            <th></th>
            <th>Nombres</th>
            <th>Apellidos</th>

            <th>Telefono</th>
            <th>Dirección</th>
            <th>DPI</th>
            <th>Creado por</th>
            <th>Estado</th>

            <th>Opciones</th>
          </tr>
        </thead>
       <tbody>
      { datos ?
           datos.map((item,index) =>(
            <tr key={index}>
               
               <td></td>
               <td>{item.nombre}</td>
               <td>{item.apellido}</td>
               <td>{item.telefono}</td>
               <td>{item.direccion}</td>
               <td>{item.dpi}</td>
               <td>{item.empleado}</td>

               {item.estado === "Activo" ? <td ><p className="activo">{item.estado}</p></td>:
               <td ><p className="noactivo">{item.estado}</p></td>
                }
               
               <td>
               <div className="d-flex dropdown justify-content-center alig-items-center">
  <i className="bi bi-three-dots " type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
   
  </i>
  <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton2">
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e)=>AbrirActualizar(item,e.target)} >Editar</li>
  <li  className="dropdown-item" onClick={()=>Eliminar(item)}>Eliminar</li>
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleFoto" onClick={(e)=>AbrirIngresoImagen(item,e)} >Fotos del cliente</li>   
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleDetalle" onClick={(e)=>VerUbicacion(item,e)} >Ver Ubicación</li>
  </ul>
</div>


                 </td>

             </tr>
           )) 
           : null
           
      
           }
      
       </tbody>
      </table>
      </div>

  
        </div>
        </div>
{/**modal para ingreso de empleado */}

  <form
          className="modal fade "
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
          onSubmit={(e)=>{GuardarCambios(e)}}
          
        >
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">

        <h5 className="modal-title"> {title} </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="form-outline mb-4">
         <label className="form-label" htmlFor="form1Example1" hidden= {true} >Codigo </label>   
    <input type="text" id="form1Example1" className="form-control" hidden= {true} value={idcliente} onChange={(e) => setIdCliente(e.target.value)} />

  </div>
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1">Nombres</label>
   
          <input type="text" id="form1Example1" className="form-control" value={nombre}  onChange={(e) => setNombre(e.target.value)}  required/>
           
  </div>
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Apellidos</label>
  
        <input type="text" id="form1Example1" className="form-control" value={apellido}  onChange={(e) => setApellido(e.target.value)} required/>
      

  </div>
  <div className="form-outline mb-4">

       <label className="form-label" htmlFor="form1Example1" >Telefono</label>
        <input type="number" id="form1Example1" className="form-control" value={telefono}  onChange={(e) => setTelefono(e.target.value.slice(0,8))} required/>

  </div>
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Dirección</label>
   
          <input type="text" id="form1Example1" className="form-control" value={direccion}  onChange={(e) => setDreccion(e.target.value)} required />
         
  </div>
  
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Numero de DPI</label>
  
        <input type="number"  id="form1Example1" className="form-control" value={dpi}  onChange={(e) => setDpi(e.target.value.slice(0,13))} required/>
      

  </div>
  

 


  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >Ubicación</label>
       <div className='input-group'>
       <input type="text" className="form-control" placeholder="ej: 14.02,-90.3" disabled={true} aria-label="Recipient's username" aria-describedby="basic-addon2" value={ubicacion}  onChange={(e) => setUbicacion(e.target.value)} required/>
  <span className="input-group-text" id="basic-addon2" onClick={()=>{getUbicacion()}}><i className='bi bi-geo-alt-fill'></i> </span>
</div>

      

  </div>

  
  <div className="form-outline mb-4 center">
       <label className="form-label" htmlFor="form1Example1">Estado</label>
       <div className="form-outline mb-4">
        <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value={estado} checked={estado === "Activo" ? true : false} onChange={() => setEstado("Activo")} selected/>
  <label className="form-check-label" htmlFor="inlineRadio1">Activo</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value={estado} checked={estado === "No Activo" ? true : false} onChange={() => setEstado("No Activo")}/>
  <label className="form-check-label" htmlFor="inlineRadio2">No activo</label>
  </div>
</div>

  </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
        <button type="submit" className="btn btn-primary" >Guardar</button>
      </div>
    </div>
  </div>
</form>
{/** fin del modal de ingreso cliente */}

{/**inicio del modal de detalles del cliente */}

<div
          className="modal fade"
          id="exampleDetalle"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
        >
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Ubicación del Cliente</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
     
       
  <div className='d-flex row gap-2 justify-content-md-center'>
 <div  className="gallery">
     <label className="form-label" htmlFor="form1Example1" >Ubicación</label>
     <label className={classTag}>Copiado!</label>
      <div className='d-flex justify-content-center'>
      <i className="bi bi-geo-alt-fill icon_location" onClick={()=>{navigator.clipboard.writeText(linkMap);
      verEtiqueta(true)
      }}></i> 
      
      </div>
      <div className='container'>
      <a href={linkMap} >ir a google maps</a> 
      </div>
  </div>
</div>

 

  
 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
       </div>
      
    </div>
  </div>
</div>
{/**fin del modal de  detalles del cliente */}

   {/**modal para administrar usuario del empledo */}

<form
onSubmit={(e)=>{GuardarCambiosFotos(e)}}
className="modal fade"
id="exampleFoto"
tabIndex="-1"
aria-labelledby="exampleModalLabel"
aria-hidden={true}
>
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title"> Fotos del Cliente</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">

      <div className="form-outline mb-4">
          <label  className="form-label" htmlFor="form1Example1" >Foto del Cliente</label>
          <div>
           <img src={previewFotoCliente}  className='preview-image' alt=''></img>  
          </div> 
        <div className="custom-file"> 
    <input type="file"  className="form-control btn-add-image"   id="formFile"  aria-describedby="inputGroupFileAddon01" name={fotoCliente} onChange={(e)=>{setFotoCliente(e.target.files[0]); GetPreview(e.target.files[0],setPreviewFotoCliente)}} required/>
  </div>

 </div>

 <div className="form-outline mb-4">
          <label  className="form-label" htmlFor="form1Example1" >Foto del Cliente y DPI</label>
          <div>
           <img src={previewFotoCliencteConDPi}  className='preview-image' alt=''></img>  
          </div> 
        <div className="custom-file"> 
    <input type="file"  className="form-control btn-add-image"   id="formFile"  aria-describedby="inputGroupFileAddon01" name={fotoDpiConCliente} onChange={(e)=>{setFotoDpiConCliente(e.target.files[0]); GetPreview(e.target.files[0],setPreviewFotoCliencteConDPi)}} required/>
  </div>

 </div>

  <div className="form-outline mb-4">
      <label  className="form-label" htmlFor="form1Example1" >Foto  de la casa</label>
      <div>
           <img src={previewFotoDeCasa}  className='preview-image' alt=''></img>  
          </div> 
      <div className="custom-file">
    <input type="file"  className="form-control btn-add-image"   id="formFile" aria-describedby="inputGroupFileAddon01" name={fotoCasaCliente} onChange={(e)=>{setFotoCasaCliente(e.target.files[0]); GetPreview(e.target.files[0],setPreviewFotoDeCasa)}} required/>
  </div>
         
  </div>

  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Foto recibo de luz</label>
      <div>
           <img src={previewReciboDeLuz}  className='preview-image' alt=''></img>  
          </div> 
      <div className="custom-file">
    <input type="file"  className="form-control btn-add-image"   id="formFile" aria-describedby="inputGroupFileAddon01" name={fotoReciboDeLuz} onChange={(e)=>{setFotoReciboDeLuz(e.target.files[0]); GetPreview(e.target.files[0],setPreviewReciboDeLuz)}} required/>
  </div>
  </div>

      <div className="form-outline mb-4">
         <label className="form-label" htmlFor="form1Example1" >Foto del DPI frontal</label>
        <div>
           <img src={previewDpiAdelante}  className='preview-image' alt=''></img>  
          </div> 
     
      <div className="custom-file">
        
    <input type="file" className="form-control btn-add-image"  id="formFile" aria-describedby="inputGroupFileAddon01" name={fotoDpiAdelante} onChange={(e)=>{setFotoDpiAdelante(e.target.files[0]); GetPreview(e.target.files[0],setPreviewDpiAdelante)}} required/>

  </div>
  </div>


  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Foto del DPI trasera</label>
      <div>
            <img src={previewDpiAtras} alt='' className='preview-image'></img>
      </div>
      <div className="custom-file">
        
    <input type="file" className="form-control btn-add-image"  id="formFile" aria-describedby="inputGroupFileAddon01" name={fotoDpiAtras} onChange={(e)=>{setFotoDpiAtras(e.target.files[0]); GetPreview(e.target.files[0],setPreviewDpiAtras)}} required/>

  </div>
  </div>
</div>
     
      <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>        
        <input type="submit" className="btn btn-primary" value="Guardar" />
        
      </div>
    </div>
    </div>
  
</form>

{/**final del modal para administrar fotos del empleado */}


     </div>   

    );
        }
    export default Cliente;
    
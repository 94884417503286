
import React, { useState,useEffect, useContext } from 'react';
import swal from "sweetalert";
import SearchBar from '../../components/SearchBar';
import Datos from '../../services/Datos';
import {Quetzal} from '../../utils/Moneda';

import moment from 'moment';


import DropDown from '../../components/DropDown';
import ls  from "local-storage";
import {AplicarMora, ConvetirPagoAData,  ObtenerTipoPago} from '../../utils/Funciones';

import {  Info} from '../../services/Info';


import { CuentaContext } from '../../context/Context';
import urlImage from "../../services/urlImage.js"
import ImprimirConstancia from '../../utils/ImprimirConstancia';


function Abono(props)  {
    const [idabono, setIdAbono] = useState("");
    const [idcuenta, setIdCuenta] = useState("");
    const [idempleado, setIdEmpleado] = useState("");
    const [concepto, setConcepto] = useState("");
    const [monto, setMonto]=useState("");
    const [tipo_pago, setTipo_pago]=useState("");
    const [comprobante, setComprobante]=useState("");
    const [mora, setMora]=useState("");
    const [fechaPago, setFechaPago]= useState("");
    const [estado, setEstado] = useState("Cancelado");
    const [prox_pago, setProx_Pago] =useState("");
    
    const [datos, setdatos] = useState([]);  
    const [encontrado, setencontrado] = useState([]);
    const [datosAbono, setDatosAbono] = useState([]);
    const [filterAbono, setFilterAbono] = useState([]);
    const [tipoPago, setTipoPago] = useState([]);
    const [filterTipoPago, setFilterPago] = useState([]);
    const [buscar, setbuscar] = useState("");
    const [abonSeleccionado, setAbonoSeleccionado]=useState([]);
    const [buscarTipoPago, setBuscarTipoPago] =useState("");
    const [accion, setAccion] = useState("new");

//datos de para la desplegar la table

const [datosCliente,setDatosCliente] =useState([]);
const [ClienteSeleccionado,setClienteSeleccionado] =useState([]);
const [linkMap, setLinkMap]=useState("");
const[prev_perfil, setPrev_perfil]=useState();
const [prev_casa, setPrev_casa ]   =useState();
const [prev_dpi, setPrev_dpi ]   =useState();
const[classTag, setClassTag]=useState("tag_copy")
const [datosEmpleado, setDatosEmpleado] = useState([])

const { verPagos,setVerPagos,cuentaSeleccionado,setCuentaSeleccionado}=useContext(CuentaContext)


  

    useEffect(()=>{
     
        setIdEmpleado(ls.get("usuario").idempleado)
       ConsultaAbono(false,cuentaSeleccionado.idcuenta);
      CunsultaTipoPago();
      ConsultarCuenta();
     ConsultarCliente();
     ConsultarEmpleado();
    },[])
    
    const ConsultarCliente=async()=>{
      const datos=await Datos.Consulta("cliente");
      if(datos!==null){
        if(datos.message === "Success"){
       
        setDatosCliente(datos.res)
        }
      }
    }
    
    const ConsultarEmpleado = async() => {
      const DataEmpleados=await Datos.Consulta("empleado");
      if(DataEmpleados!=null){
        setDatosEmpleado(DataEmpleados.res);
        return
      }
      setDatosEmpleado([]);
    }
    

const  returnCliente=(idcliente)=>{
for (let i  in datosCliente){
  if(datosCliente[i].idcliente===idcliente){
    return datosCliente[i];
  }
}
    }
    const ConsultarCuenta=async()=>{
      const datos=await Datos.Consulta("cuenta");
      if(datos!==null){
        if(datos.message ==="Success"){
        
        setdatos(datos.res);
        setencontrado(datos.res)
        }
      }
    }
const ConsultaAbono = async (reverse, id) => {
  const datos_Abono= id <= 0 ? await Datos.Consulta("abono") : await Datos.ConsultaAbonoXP(id);
  if(datos_Abono!==null){
    if(datos_Abono.message==="Success"){
    
   // let abonoAsc= reverse ? datos_Abono.res.reverse() : datos_Abono.res;
    setDatosAbono(datos_Abono.res);
    setFilterAbono(datos_Abono.res);
    }else{
      setDatosAbono([])
      setFilterAbono([])
      swal("Aviso","Esta cuenta no tiene pagos efectuados, seleccione otra!","warning");
    }
  }
}
const CunsultaTipoPago = async() => {
  const datosPago=await Datos.Consulta("tipoPago");
  if(datosPago !==null){
setTipoPago(datosPago.res);
setFilterPago(datosPago.res)
  }
}
    const limpiar=()=>{
     
      setIdAbono(0) ;
      setIdCuenta(0);
    //  setIdEmpleado("");
      setMonto("");
      setConcepto("");
    setTipo_pago("")
    setComprobante("")
      setBuscarTipoPago("")
      setMora("")
      setEstado("Cancelado");
      setAccion("new")
    }
    const Ingresar=async()=>{
      let datos={
        idabono:0,
        idcuenta: cuentaSeleccionado.idcuenta,
        idempleado:idempleado,
        concepto:concepto,
        monto:monto,
        tipo_pago:tipo_pago,
        comprobante:comprobante,
        //fecha:moment(fecha).format("YYYY-MM-DD"),
        mora:mora,
        prox_pago:prox_pago !=="" ? moment(prox_pago).format("YYYY-MM-DD h:mm:ss") : moment(new Date()).format("YYYY-MM-DD h:mm:ss"),
        estado:estado
      }
   
      let Abono=await Datos.NuevoReg("abono",datos);
      if(Abono !== null){
        if(Abono.message==="Success"){
          swal("Abono","Ingresdo exitosamente","success");
    

          limpiar();
          ConsultaAbono(true,cuentaSeleccionado.idcuenta)
          ConsultarCuenta();
        
        }else{
          swal("Abono","No se pudo Ingresar, verifique los datos","warning");
        }
      }
    }


    const Actualizar=async()=>{
      let datos={
        idabono:idabono,
        idcuenta: cuentaSeleccionado.idcuenta,
        idempleado:idempleado,
        concepto:concepto,
        monto:monto,
        tipo_pago:tipo_pago,
        comprobante:comprobante,
        mora:mora,
        prox_pago:prox_pago !=="" ? moment(prox_pago).format("YYYY-MM-DD h:mm:ss") : moment(new Date()).format("YYYY-MM-DD h:mm:ss"),
        estado:estado
      }
     
      let Abono=await Datos.ActualizarReg("abono",datos);
      if(Abono !== null){
        if(Abono.message==="Success"){          
          swal("Abono","Ingresdo exitosamente","success");
        
          limpiar();
          ConsultarCuenta();
          ConsultaAbono(true,cuentaSeleccionado.idcuenta);
       
        }else{
          swal("Abono","No se pudo Ingresar, verifique los datos","warning");
        }
      }
    }
    const Eliminar=async(id)=>{
      let Abono=await Datos.BorrarReg("abono",id);
      if(Abono!==null){
        if(Abono.message === "Success"){
          swal("Abono", "Eliminado con exíto","success")
          ConsultarCuenta();
          ConsultaAbono(false,cuentaSeleccionado.idcuenta)
        }else{
          swal("Abono","No se pudo eliminar","warning");
        }
      }
    }
    const GuardarCambios=()=>{
      if(accion==="new"){
        Ingresar();
      }else{
        Actualizar();
      }
    }
const AbrirActualizar=(datos,e)=>{
  
let TipoActual=ObtenerTipoPago(tipoPago,datos.tipo_pago)
setBuscarTipoPago(TipoActual.nombre);
//setcuentaSeleccionado(returnCuenta(datos.idcuenta))
setIdAbono(datos.idabono)
setIdCuenta(datos.idcuenta);
setIdEmpleado(datos.idempleado);
setConcepto(datos.concepto);
setMonto(datos.monto);
setFechaPago(datos.fecha)
setTipo_pago(datos.tipo_pago);
setComprobante(datos.comprobante);


if(AplicarMora(new Date(), datos.fecha)){
  
setMora(Info.mora)
}else{
setMora(datos.mora !==null ? datos.mora : 0 )
}

setEstado(datos.estado)
setAccion("update");

var myInput = document.getElementById("exampleModal");
    e.addEventListener("shown.bs.modal", function () {
      myInput.focus();
    });
    }

const returnCuenta = (idcuenta) => { 
for(let i in  datos){
  if(datos[i].idcuenta === idcuenta){
    return datos[i]
  }
}
}

    const Busqueda =(e)=>{
      let buscarTexto=e.target.value;
      setbuscar(buscarTexto);
      let text=buscarTexto.replace(/^\w/,(c) =>c.toLowerCase());
      setbuscar(buscarTexto);
      
      setDatosAbono(filterAbono.filter(function(item){
          return   item.concepto.toLowerCase().includes(text)|| item.comprobante.toLowerCase().includes(text) || item.estado.toLowerCase().includes(text) ;   
        }).map(function({idcuenta, idempleado, concepto, monto,tipo_pago,comprobante,mora, estado}){
          return{idcuenta, idempleado, concepto, monto,tipo_pago,comprobante,mora, estado}
        })
       );
      
        }
    
  const AbrirIngreso=(e)=>{

    limpiar();
  let myInput = document.getElementById("exampleModal");
   e.target.addEventListener("shown.bs.modal", function () {
      myInput.focus();
    })
   
  }


 const AbrirDetalle = (item,e) => { 
setAbonoSeleccionado(item);
let myInput = document.getElementById("exampleDetalle");
e.addEventListener("shown.bs.modal", function () {
  myInput.focus();
});
  } 

  const BusquedaPago=(e)=>{
    let buscarTexto=e.target.value;
    setBuscarTipoPago(buscarTexto);
    let text=buscarTexto.replace(/^\w/,(c) =>c.toLowerCase());
    setBuscarTipoPago(buscarTexto);
    
    setTipoPago(filterTipoPago.filter((item)=>{ 
        return   item.nombre.toLowerCase().includes(text) ;   
      }).map(({idtipopago, nombre})=>{
        return{idtipopago, nombre}
      })
     );
    
      }
     
    


        
        
         
          
     
const AbrirUbicacion = (item, e) => { 
  let cliente=returnCliente(item.idcuenta);
setClienteSeleccionado(cliente)

  let ubica=cliente.ubicacion.split(",");
     let lat=ubica[0];
  let log=ubica[1];
  
setPrev_casa(urlImage(cliente.f_casa));
setPrev_dpi(urlImage(cliente.f_dpi));
setPrev_perfil(urlImage(cliente.f_perfil));



setLinkMap(`https://www.google.es/maps?q=${lat},${log}`);
  
  var myInput = document.getElementById("exampleUbicacion");
    e.addEventListener("shown.bs.modal", function () {
      myInput.focus();
    });
}


const verEtiqueta = (visible) => { 
  if(visible){
    setClassTag("tag_copy tag_visible")
    setTimeout(()=>{
      setClassTag("tag_copy")
    },1000);

  }else{
setClassTag("tag_copy")
  }
 }

const AbrirNuevoCobrador = (item,e) => {
  setAbonoSeleccionado(item);

}

const CambiarCobrador =async (e) => {
  e.preventDefault();
  let datos={
    idabono:abonSeleccionado.idabono,
    idcuenta: cuentaSeleccionado.idcuenta,
    idempleado:idempleado,
    concepto:2,
    monto:2,
    tipo_pago:2,
    comprobante:2,
    mora:2,
    prox_pago:prox_pago !=="" ? moment(prox_pago).format("YYYY-MM-DD h:mm:ss") : moment(new Date()).format("YYYY-MM-DD h:mm:ss"),
    estado:estado
  }

 
  let Abono=await Datos.ActualizarReg("abono/empleado",datos);
  if(Abono !== null){
    if(Abono.message==="Success"){          
      swal("Abono","Ingresdo exitosamente","success");
    
      limpiar();
      ConsultarCuenta();
      ConsultaAbono(true,cuentaSeleccionado.idcuenta);
   
    }else{
      swal("Abono","No se pudo Ingresar, verifique los datos","warning");
    }
  }
}


const ImprimirBoleta = (codigoAbono) => { 
  ImprimirConstancia(codigoAbono)
}
const RegresarACuenta = () => {
  setVerPagos(false)
  setCuentaSeleccionado([])
}



    return(
        <div>


            <div className='div-header'>   
              <div className='d-flex mt-2 mb-2  align-items-center'>

           
            <button className='btn btn-info text-white' onClick={()=>RegresarACuenta()}>
<i className="bi bi-arrow-left"></i> <span className='ms-2'>Atras</span>
  </button> <h5 className="modal-title ms-2">{`Pagos de: ${cuentaSeleccionado.cliente}, prestamo de: Q${cuentaSeleccionado.monto}`}</h5>
             </div>
            <SearchBar
            onChange={Busqueda} 
            value={buscar} 
            placeholder="Buscar Abono..."  
            data_bs_toggle="modal"
            data_bs_target="#exampleModal"
            onClick={AbrirIngreso}
            />
         

  
   

            </div>
         <div className='div-body'>

         <div className="div-table ">
<div className="table-wrap">
  
<table className="table-item ">
  <thead >
          <tr>
            <th></th>
            <th>Concepto</th>
            <th>Monto</th> 
            <th>Tipo de  Pago</th>
            <th>Comprobante</th>
            <th>Fecha Pago</th>  
            <th>Dia cobrado</th>           
            <th>Mora</th>
            <th>Estado</th>
            
            <th>Opciones</th>
          </tr>
        </thead>
       <tbody>
      { datosAbono.length > 0 ?
           datosAbono.map((item,index) =>(
            <tr key={index} >
               
               <td></td>           
               <td>{item.concepto}</td>  
               <td>{Quetzal(item.monto)}</td>  
               <td>{item.nombre}</td>  
               <td>{item.comprobante}</td>  
               <td>{moment(item.fecha).format("DD/MM/YYYY")}</td>
               <td>{moment(item.diacobro).format("DD/MM/YYYY")}</td>
               <td>{Quetzal(item.mora)}</td>  
              <td><p className={item.estado === "Cancelado" ? "activo" : "noactivo" &&  item.estado === "Pendiente" ? "pendiente" : "noactivo"  }>{item.estado}</p></td> 
              
               
               <td>
               <div className="d-flex dropdown justify-content-center alig-items-center">
  <i className="bi bi-three-dots " type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
   
  </i>
  <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton2">
  <li  className="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleDetalle" onClick={(e)=>AbrirDetalle(item,e.target)}>Cobrado por:</li>  
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleUbicacion" onClick={(e)=>AbrirUbicacion(item,e.target)} >Información del Cliente</li>  
  <li className=" dropdown-item"  onClick={()=>ImprimirBoleta(item.idabono)} >Imprimir constancia de pago</li>
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e)=>AbrirActualizar(item,e.target)} >Editar</li>
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleCobrador" onClick={(e)=>AbrirNuevoCobrador(item,e.target)} >Cambiar Cobrador</li>
  <li  className="dropdown-item" onClick={()=>Eliminar(item.idabono)}>Eliminar</li>
      
   
  </ul>
</div>


                 </td>

             </tr>
           )) 
           : 
 null
           }
      
       </tbody>
      </table>
      </div>

  
        </div>
         </div>
{/**modal para ingreso de abono */}

  <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
        >
        
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Ingreso de Abono</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="form-outline mb-4">
         <label className="form-label" htmlFor="form1Example1" hidden= {true} >Codigo de abono</label>   
    <input type="text" id="form1Example1" className="form-control" hidden= {true} value={idabono} onChange={(e) => setIdAbono(e.target.value)} />

  </div>
 
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >Concepto</label>
        <input type="text" id="form1Example1" className="form-control" value={concepto}  onChange={(e) => setConcepto(e.target.value)} />

  </div>
  <div className="form-outline mb-4">
  <label className="form-label" htmlFor="form1Example1" >Monto</label>
      <div className='input-group'>
          <span className="input-group-text">Q</span>
          <input type="number" id="form1Example1" className="form-control" value={monto}  onChange={(e) => setMonto(e.target.value)} />
          <span className="input-group-text">.00</span>
      </div>
       
  </div>

  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Tipo de Pago</label>
      <DropDown 
        dato = {ConvetirPagoAData(tipoPago)} 
        selected={tipo_pago} 
        setSelected={setTipo_pago}  
        value={buscarTipoPago}  
        setValue={setBuscarTipoPago} 
        onChange={BusquedaPago}
      />
  
   
      

  </div>
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >Comprobante</label>
        <input type="text" id="form1Example1" className="form-control" value={comprobante}  onChange={(e) => setComprobante(e.target.value)} />

  </div>
  <label className="form-label" htmlFor="form1Example1" >Mora</label>
      <div className='input-group'>
          <span className="input-group-text">Q</span>
          <input type="number" id="form1Example1" className="form-control" value={mora}  onChange={(e) => setMora(e.target.value)} />
          <span className="input-group-text">.00</span>
      </div>

  
  <div className="form-outline mb-4 center">
       <label className="form-label" htmlFor="form1Example1">Estado</label>
       <div className="form-outline mb-4">
        <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value={estado} checked={estado === "Cancelado" ? true : false} onChange={() => setEstado("Cancelado")} selected/>
  <label className="form-check-label" htmlFor="inlineRadio1">Cancelado</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value={estado} checked={estado === "Atrasado" ? true : false} onChange={() => setEstado("Atrasado")}/>
  <label className="form-check-label" htmlFor="inlineRadio2">Atrasado</label>
  </div>
  
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value={estado} checked={estado === "Pendiente" ? true : false} onChange={() => setEstado("Pendiente")}/>
  <label className="form-check-label" htmlFor="inlineRadio2">Pendiente</label>
  </div>
</div>

  </div>
  {/**
  <div className="form-outline mb-4 center">
       <div className="form-check mb-3">
       <input className="form-check-input mt-0" type="checkbox" value={isDisabled} checked={isDisabled} onClick={()=>{setIsDisabled(!isDisabled)}}  data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"/>
       <label   className="form-check-label"  > Agregar siguiente pago </label>
       </div>
       <div className="collapse" id="collapseExample">
 <div className="form-outline mb-3">
<label className="form-label" htmlFor="form1Example1">Fecha del proximo pago</label>
  <input disabled={!isDisabled} type="date" aria-label="Text input with checkbox" className="form-control form-control-sm"  placeholder='01/02/2022' value={prox_pago} onChange={(e)=>setProx_Pago(moment(e.target.value).format("YYYY-MM-DD"))}/>
 
</div>
  
      
<div className='form-outline mb-3'>
<label className="form-label" htmlFor="form1Examples1">Monto del proximo pago</label>
 <input disabled={!isDisabled} type="number" className="form-control form-control-sm"  placeholder='ej.: 10.00' value={prox_monto} onChange={(e)=>setProx_monto(e.target.value)}/>
</div>
</div>
       </div>
     */} 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" className="btn btn-primary" onClick={()=>GuardarCambios()} >Guardar</button>
      </div>
    </div>
  </div>


</div>

{/** modal detalle cobrador  */}
<div
         className="modal fade"
          id="exampleDetalle"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
        >
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Detalle del  Pago</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      
  <div className="form-outline mb-4">
       <h6 className="form-label" htmlFor="form1Example1" >Cobrador:</h6>
  </div>
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >{abonSeleccionado.empleado}</label>
  </div>
  
 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
       
      </div>
    </div>
  </div>
</div>
{/* final del modal de detalle*/}

{/** modal detalle cambiar cobrador  */}
<form
         className="modal fade"
          id="exampleCobrador"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
          onSubmit={(e)=>CambiarCobrador(e)}
        >
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Cambiar cobrador del  Pago</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      
  <div className="form-outline mb-4">
       <h6 className="form-label" htmlFor="form1Example1" >Cobrador actual:</h6>
  </div>
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >{abonSeleccionado.empleado}</label>
  </div>
      
  <div className="form-outline mb-4">
       <h6 className="form-label" htmlFor="form1Example1" >Seleccionar nuevo cobrador:</h6>
  </div>
  <div className="form-outline mb-4">
      <select className='form-select' value={idempleado} onChange={(e)=>setIdEmpleado(e.target.value)}>
        {
        datosEmpleado.length > 0 ?
        datosEmpleado.map((item, index)=>(
          <option key={index} value={item.idempleado}>{`${item.nombre}  ${item.apellido}`}</option>
        ))
        :
        null
      }
      </select>
  </div>
 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
        <button type="submit" className="btn btn-primary" >Guardar Cambios</button>
       
      </div>
    </div>
  </div>
</form>
{/* final del modal de cambiar cobrador*/}
{/** modal detalle de la ubicaicon del clienter  */}
<div
         className="modal fade"
          id="exampleUbicacion"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
        >
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Información del cliente</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
      <div className="modal-body">
     
      <div className="form-outline mb-4">
       <h6 className="form-label" htmlFor="form1Example1" >Cliente:</h6>
  </div>
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >{ClienteSeleccionado.nombre+' '+ClienteSeleccionado.apellido}</label>
  </div>
  <div className="form-outline mb-4">
       <h6 className="form-label" htmlFor="form1Example1" >Dirección:</h6>
  </div>
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >{ClienteSeleccionado.direccion}</label>
  </div>
  <div className='d-flex row gap-2 justify-content-md-center'>
  <div className="gallery" >
  <img src={prev_perfil} className="imgs" alt="..."/>
  <div className="div_dec">
    <label className="card-title">Foto del cliente</label> 
  </div>
</div>
<div className="gallery" >
  <img src={prev_casa} className="imgs" alt="..."/>
  <div className="div_dec">
    <label className="card-title">Foto de la residencia </label> 
  </div>
</div>
<div className="gallery">
  <img src={prev_dpi} className="imgs" alt="..."/>
  <div className="div_dec">
    <label className="card-title">Foto del DPI</label> 
  </div>
</div>
 <div  className="gallery">
     <label className="form-label" htmlFor="form1Example1" >Ubicación</label>
     <label className={classTag}>Copiado!</label>
      <div className='d-flex justify-content-center'>
      <i className="bi bi-geo-alt-fill icon_location" onClick={()=>{navigator.clipboard.writeText(linkMap);
      verEtiqueta(true)
      }}></i> 
      
      </div>
      <div className='container'>
      <a href={linkMap} >ir a google maps</a> 
      </div>
  </div>
</div>

 

  
 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
       
      </div>
    </div>
  </div>
</div>
{/* final del modal de ubicaicion del del cliente*/}


     </div>   

    );
        }
    export default Abono;
    /**
     *   <div className='dropdown_table' onClick={()=>setOpen(!open)}>
                <div className='input-group mb-2 '>
                <input type='text' 
                className='form-control'
                placeholder='Buscar Cuenta...' 
                value={buscarCuenta}
                onChange={(e)=>{BusquedaCuenta(e.target.value)}}
                />
                {
                open ? <i className="bi bi-caret-up-fill input-group-text"></i> : <i className="bi bi-caret-down-fill input-group-text"></i>
                }
                </div>
                <div className={open ? 'open_table' : 'options_table'}>
                  
<div className="div-table">
<div className="table-wrap">
  
<table className="table-item ">
  <thead >
          <tr>
            <th>#</th>
            <th>Cliente</th>
            <th>Plan</th>
            <th>Apertura</th> 
        
            <th>Abonado</th>
            <th>Mora</th>  
            <th>Estado</th>
          </tr>
        </thead>
       <tbody>
      { datos ?
           datos.map((item,index) =>(
            <tr key={index}  onClick={()=>{ItemSeleccionado(item)}}>
               
               <td>{item.idcuenta}</td>
               <td>{item.cliente}</td>
               <td>{Quetzal(item.monto)}</td>  
               <td>{moment(item.fecha).format("DD/MM/YYYY")}</td>
               <td>{Quetzal(item.totalabono)}</td>  
               <td>{Quetzal(item.totalmora)}</td>
             
               {item.estado === "Activo" ? <td ><p className="activo">{item.estado}</p></td>:
               <td ><p className="noactivo">{item.estado}</p></td>
                }


             </tr>
           )) 
           : null
           
      
           }

      
       </tbody>
      </table>
      </div>

  
        </div>
                
              </div>
    </div>
    
     */
import md5 from 'md5';
import React,{useEffect, useState} from 'react'
import swal from 'sweetalert';
import Datos from '../../services/Datos';
import SearchBar2 from '../../components/SearchBar2';
import HeaderBar from '../Menu/HeaderBar';

function  Usuario() {
//const {usuario, setusuario}=useState(usuarios.usuario !== undefined ? usuarios.usuario : "");
//const {pass,setpass}=useState(usuarios.pass !== undefined ? usuarios.pass : "");
const [usuario, setUsuario] = useState("")
const [pass, setPass] = useState("");
const [title, setTitle] = useState("")
const [labelButton, setLabelButton] = useState("")
const [empleadoSelected, setEmpleadoSelected] = useState([])
const [accion, setAccion] = useState("new")
const [dataUser, setDataUser] = useState([]);
const [dataEmpleado, setDataEmpleado] = useState([]);
const [dataEmpleadoAux, setdataEmpleadoAux] = useState([])
const [buscar, setBuscar] = useState("")

useEffect(()=>{
GetEmployee();
},[])

async function GetEmployee(){
    const getEmployee=await  Datos.Consulta("empleado");
    if(getEmployee !=null){
     
        setDataEmpleado(getEmployee.res);
        setdataEmpleadoAux(getEmployee.res);
        return;
    }
    setDataEmpleado([]);
    setdataEmpleadoAux([]);
}

 const Busqueda = (e) => {
   
 }
 

 const GetEmployeeUser =async (item) => {
    setEmpleadoSelected(item)
    const datosUser=await Datos.ConsutaIDUser("usuario",item.idempleado);
   if(datosUser!==null){
    setDataUser(datosUser.res);
    setUsuario(datosUser.res[0].usuario);
    setPass(datosUser.res[0].pass);
    setAccion("update");
    setTitle(`Usuario del empleado ${item.nombre} ${item.apellido}`);
    
    return
    }
    setDataUser([]);
    setUsuario("");
    setPass("");
    setTitle(`El empleado ${item.nombre} ${item.apellido} aun no tiene usuario y contraseña llene el formulario.`);
    setAccion("new");
    }
    
  
  
                  
const guardaruser = () => { 
  
  let datos={
      idusuario: accion ==="new" ? 0 : dataUser[0].idusuario,
      idempleado:empleadoSelected.idempleado,
      usuario:usuario,
      pass:md5(pass),
  }
  if(accion ==="new"){
    ingresarUser(datos)
  }else{
    actualizarUser(datos)
  }

  
   }
  const ingresarUser = async(datos) => { 
      let ingresado=await Datos.NuevoReg("usuario",datos);
      if(ingresado !==null){
          if(ingresado.message==="Success"){
              swal("","Se ingreso correctamente, ahora puede modificarlo o salir","success");
              GetEmployeeUser(empleadoSelected)
          }else{
          swal("","No Se ingreso ","error");
          }
      }
   }
   
  const actualizarUser = async(datos) => { 
      let ingresado=await Datos.ActualizarReg("usuario",datos);
      if(ingresado !==null){
          if(ingresado.message==="Success"){
              swal("","Usuario y Contraseña Actualizo correctamente","success");
              GetEmployeeUser(empleadoSelected)
          }else{
          swal("","No Se ingreso ","error");
        }
      }
   }
 

  return (
    <div className='row'> 
    <div className='col-md-6 col-sm-12'>
        <div className='div-header'>
          <HeaderBar  title="Empleado"  />
        <div className="mb-2">   
            <h5 className="modal-title">Empleado</h5></div>
            <SearchBar2
            onChange={Busqueda} 
            value={buscar} 
            placeholder="Buscar Empleado..."  
          

            
            /> 
        </div>
        <div className='div-body'>
        <div className="div-table">
<div className="table-wrap">
  
<table className="table-item ">
  <thead >
          <tr>
            <th>Empleado</th>
          
          </tr>
        </thead>
       <tbody>
      { dataEmpleado.length >0 ?
           dataEmpleado.map((item,index) =>(
            <tr key={index} onClick={()=>GetEmployeeUser(item)}>               
            <td>{item.nombre+ " "+item.apellido}</td>             
             </tr>
           )) 
           : null
           
      
           }
      
       </tbody>
      </table>
      </div>

  
        </div>
        </div>
    </div>
    <div className='col-md-6 col-sm-12'>
   
        <div className=''>
             <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example6" >{title}</label>
     
     
    </div>  
              <div className="form-outline mb-3">
       <label className="form-label" htmlFor="form1Example6" >Usuario</label>
       <input type="text" id="form1Example6" className="form-control" value={usuario}  onChange={(e) => setUsuario(e.target.value)} />
     
    </div>  
    <div className="form-outline mb-3">
         <label className="form-label" htmlFor="form1Example7" >Contraseña</label>
          <input type="password" id="form1Example7" className="form-control" value={pass}  onChange={(e) => setPass(e.target.value)} />
  
    </div> 
    <div className="form-outline mb-3 d-flex justify-content-center">
    <button type="button" className="btn btn-primary" onClick={()=>guardaruser()} >Guardar Cambios</button>
    </div>
  </div>
      
      
   </div>
    
    


   
    </div>
  )
}

export default Usuario
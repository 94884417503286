import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducers/user/userSlice";
import accesSlice from "./reducers/acces/accesSlice"; 
import openMenuSlice from "./reducers/openMenu/openMenuSlice";
import centerSlice from "./reducers/center/centerSlice";
import grantedSlice from "./reducers/granted/grantedSlice";

export default configureStore({
    reducer:{
        user:userSlice,
        acces:accesSlice,
        open:openMenuSlice,
        center:centerSlice,
        granted:grantedSlice
    }
})
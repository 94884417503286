import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen } from '../../app/reducers/openMenu/openMenuSlice';

function HeaderBar(props) {
  const dispatch=useDispatch();
  const {open}=useSelector(state=>state.open)
  const ShowMenu = () => {
    dispatch(setOpen({
      open:!open
    }))
    
  }
  
  return (
    <div className='d-flex align-items-center'>
      <div className=''><i className="bi bi-list fs-2" onClick={ShowMenu}></i></div>
      <div className='ms-3 mt-2'>
      <h4>{props.title}</h4>
    
      </div>
    
    </div>
  )
}

export default HeaderBar

import { createSlice } from "@reduxjs/toolkit";

const initialState={
    accesList:[]
}
export const accesSlice=createSlice({
    name:"acces",
    initialState:initialState,
    reducers:{
        addAccessList:(state, action)=>{
            state.accesList=[...action.payload]
        },
        removeAccesToList:(state)=>{
            state.accesList=[];
        }
    }
});

export const {addAccessList,removeAccesToList}=accesSlice.actions;
export default accesSlice.reducer;

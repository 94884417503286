import React,{useState,useEffect} from 'react'
import swal from 'sweetalert';
import Datos from '../../services/Datos';
import SearchBar2 from '../../components/SearchBar2';
import HeaderBar from '../Menu/HeaderBar';

function Permisos() {

  const[datosPermiso, setDatosPermiso]=useState([]);
  const [modulo, setmodulo] = useState([]);
  const[empleadoSelected,setEmpeladoSelected]=useState([]);
  const [dataEmpleado, setDataEmpleado] = useState([]);
const [dataEmpleadoAux, setdataEmpleadoAux] = useState([])
const [buscar, setBuscar] = useState("")

useEffect(()=>{
ConsultarModulo();
GetEmployee();
},[])


async function GetEmployee(){
  const getEmployee=await  Datos.Consulta("empleado");
  if(getEmployee !=null){
   
      setDataEmpleado(getEmployee.res);
      setdataEmpleadoAux(getEmployee.res);
      return;
  }
  setDataEmpleado([]);
  setdataEmpleadoAux([]);
}


const ConsultarModulo=async()=>{

  const datos=await Datos.Consulta("modulo");
//datos  && datos.res && setdatos(datos.res); setencontrado(datos.res);
 if(datos!==null){
   
    setmodulo(datos.res);
  }
}
 const ConsultarPermiso=async(datos )=>{
  setEmpeladoSelected(datos)
  const dats=await Datos.ConsutaIDUser("permiso",datos.idempleado);
 if(dats!==null){
   
    setDatosPermiso(dats.res)
    return
  }
  setDatosPermiso([])
}
const agregarPermiso = () => { 
  if(empleadoSelected.idempleado >0){
   for(let i in modulo){
      ingresarPermiso(empleadoSelected.idempleado,modulo[i].idmodulo)
      if(i>=modulo.length - 1){
        ConsultarPermiso(empleadoSelected)
      }
    }
    return;
  }
  swal("Credi Mex", "No se puede agregar los permisos, primero seleccione el empleado","warning")
 }

const ingresarPermiso = async(idempleado, idmodulo) => { 
  let datos={
    idpermiso:0,
    idempleado:idempleado,
    idmodulo:idmodulo,
    permiso:0,
  }
  let permisoIngresado=await Datos.NuevoReg("permiso",datos);
  if(permisoIngresado!==null){
    if(permisoIngresado.message==="Success"){
      swal("","Permiso Ingreado","success")
    }else{
    swal("","No se ingreso el permiso","error")}
  }
 }




const ActualizarPermiso =async (item, permiso) => { 
let datosPermiso={
idpermiso:item.idpermiso,
idempleado:item.idempleado,
idmodulo:item.idmodulo,
permiso:permiso ? 1 : 0
}
let dtpermiso=await Datos.ActualizarReg("permiso",datosPermiso);
if(dtpermiso !==null){
if(dtpermiso.message==="Success"){
ConsultarPermiso(empleadoSelected);
swal("Aviso", "Se Actualizaron correctamente los permisos","success");  
}else{
swal("Aviso", "No se pudo actualizar el permiso","error");
}
}
} 
const Busqueda = (params) => {
  
}

  return (
    <div className='row'>

<div className='col-md-6 col-sm-12'>
        <div className='div-header'>
          <HeaderBar  title="Empleado"  />
      
            <SearchBar2
            onChange={Busqueda} 
            value={buscar} 
            placeholder="Buscar Empleado..."  
          

            
            /> 
        </div>
        <div className='div-body'>
        <div className="div-table">
<div className="table-wrap">
  
<table className="table-item ">
  <thead >
          <tr>
            <th>Empleado</th>
          
          </tr>
        </thead>
       <tbody>
      { dataEmpleado.length >0 ?
           dataEmpleado.map((item,index) =>(
            <tr key={index} onClick={()=>ConsultarPermiso(item)}>               
            <td>{item.nombre+ " "+item.apellido}</td>             
             </tr>
           )) 
           : null
           
      
           }
      
       </tbody>
      </table>
      </div>

  
        </div>
        </div>
    </div>
    <div className='col-md-6 col-sm-12'>
   
    <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Administrar permisos</h5>
        
      </div>
      <div className="modal-body">
     {
      datosPermiso.length > 0 ?

      <div className="div-table">
      <div className="table-wrap">
        <table className="table-subitem">
      <thead>
      <tr>
      <th></th>
        <th >Modulo</th>
        <th>Permiso</th>
       
      </tr>
      </thead>
      <tbody>
   {
      datosPermiso.map((item,index)=>(
         <tr key={index}>
           <td></td>
        <td>{item.nombre}</td>
        <td>
          <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" id="escritura"  checked={item.permiso === 1 ? true : false } onChange={(e)=>ActualizarPermiso(item, e.target.checked)}/>
          </div> 
        </td>
       
      </tr> 
      ))
      
    }
      
      
      </tbody>
      </table>
      </div>
        </div>

      :
   empleadoSelected.idempleado > 0?
     <div>
          
      <div>
         <h6>{`El empleado ${empleadoSelected.nombre} ${empleadoSelected.apellido} aun no tiene permiso. Si desea agregar, haga clic en boton agregar permisos`}</h6>
      <p>Al agregar por primera vez los permisos, todos estan desactivados</p>
      <button type="button" className="btn btn-primary" onClick={()=>agregarPermiso()} >Agregar Permisos</button>
           </div>

     
     </div>
 :null
      
     }
      </div>
    
    </div>
  </div>
      
   </div> 
   </div>  
  



  )
}

export default Permisos

import React, { useState,useEffect } from 'react';
import md5 from "md5";
import swal from "sweetalert";
import SearchBar from '../../components/SearchBar';
import Datos from '../../services/Datos';
import {Quetzal, Dolar} from '../../utils/Moneda';
import ls from "local-storage";
import DropDown from '../../components/DropDown';
import moment from 'moment';
import {ConvetirPagoAData, ObtenerTipoPago} from '../../utils/Funciones';
import HeaderBar from '../Menu/HeaderBar';
function Reporte(props)  {
    const [identregado, setIdEntregado] = useState("");
    const [idempleado, setIdEmpleado] = useState("");
    const [cantidad, setCantidad] = useState("");
    const [tipo_entrega, setTipoEntrega] = useState("");
    const [comprobante, setComprobante] = useState(""); 
    const [fecha, setFecha]=useState(moment(new Date()).format("YYYY-MM-DD"));
    const [estado, setEstado] = useState("Activo");
    const [tipoPago, setTipoPago] = useState([]);
    const [filterTipoPago, setFilterPago] = useState([]);
    const [buscarTipoPago, setBuscarTipoPago] =useState("");
    const [datos, setdatos] = useState([]);  
    const [encontrado, setencontrado] = useState([]);
    const [buscar, setbuscar] = useState("");
    const [accion, setAccion] = useState("new");
    
    


    useEffect(()=>{
      let codigoEmpleado=ls.get("usuario").idempleado;
      setIdEmpleado(codigoEmpleado);
        CunsultaTipoPago();
      ConsultarEntrega();
  
    },[])
    
    const ConsultarEntrega=async()=>{
      const datos=await Datos.Consulta("entrega");
      if(datos!==null){
       
        setdatos(datos.res);
        setencontrado(datos.res)
        return;
      }
      setdatos([]);
      setencontrado([]);
    }
    const CunsultaTipoPago = async() => {
        const datosPago=await Datos.Consulta("tipoPago");
        if(datosPago !==null){
      setTipoPago(datosPago.res);
      setFilterPago(datosPago.res)
        }
      }

    const limpiar=()=>{
      setIdEntregado(0);
      setIdEmpleado(0);
      setCantidad("");
      setTipoEntrega("");
      setComprobante("");
      setFecha("");
    }
 
const DATOSENTREGA=(codigoEntrega)=>{
  return  {
    identregado:codigoEntrega,
    idempleado:idempleado,
    cantidad:cantidad,
    tipo_entrega:tipo_entrega,
    comprobante:comprobante,
    fecha:moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
  }
}

   const Ingresar=async()=>{
   
        let entrega=await Datos.NuevoReg("entrega",DATOSENTREGA(0));
        if(entrega !== null){
          if(entrega.message==="Success"){
            swal("Reporte de cobro","Ingresdo exitosamente","success");
            limpiar();
            ConsultarEntrega();
          }else{
            swal("Reporte de cobro","No se pudo Ingresar, verifique los datos","warning");
          }
        }
      }

    
      const Actualizar=async()=>{
     
        let entrega=await Datos.ActualizarReg("entrega",DATOSENTREGA(identregado));
        if(entrega !== null){
          if(entrega.message==="Success"){
            swal("Reporte de cobro","Ingresado exitosamente","success");
            limpiar();
            ConsultarEntrega();
          }else{
            swal("Reporte de cobro","No se pudo Ingresar, verifique los datos","warning");
          }
        }
      }
    
    const Eliminar=async(id)=>{
      let entrega=await Datos.BorrarReg("entrega",id);
      if(entrega!==null){
        if(entrega.message === "Success"){
          swal("Entrega", "Eliminado con exíto","success")
          ConsultarEntrega();
        }else{
          swal("Entrega","No se pudo eliminar","warning");
        }
      }
    }
    const GuardarCambios=()=>{
      if(accion==="new"){
        Ingresar();
      }else{
        Actualizar();
      }
    }
    const AbrirActualizar=(datos,e)=>{
setIdEntregado(datos.identregado);
setIdEmpleado(datos.idempleado);
setCantidad(datos.cantidad);
setTipoEntrega(datos.tipo_entrega);
setComprobante(datos.comprobante);
setFecha(datos.fecha);
setAccion("update");

var myInput = document.getElementById("exampleModal");
    e.addEventListener("shown.bs.modal", function () {
      myInput.focus();
    });
    }
    const Busqueda =(e)=>{
      let buscarTexto=e.target.value;
    
      let text=buscarTexto.toLowerCase();
      setbuscar(buscarTexto);
      
      setdatos(encontrado.filter(function(item){
          return   item.tipopago.toLowerCase().includes(text) ;   
        }).map(function(element){
          return element
        })
       );
      
        }
    
  const AbrirIngreso=(e)=>{
    let myInput = document.getElementById("exampleModal");
    e.target.addEventListener("shown.bs.modal", function () {
      myInput.focus();
    });
  }
  const BusquedaPago=(e)=>{
    let buscarTexto=e.target.value;
 
    let text=buscarTexto.replace(/^\w/,(c) =>c.toLowerCase());
    setBuscarTipoPago(buscarTexto);
    
    setTipoPago(filterTipoPago.filter((item)=>{ 
        return   item.nombre.toLowerCase().includes(text) ;   
      }).map(({idtipopago, nombre})=>{
        return{idtipopago, nombre}
      })
     );
    
      }
 
  
    return(
        <div>
          <div className='div-header'>
            <HeaderBar  title="Reporte final del día"  />
           
            <SearchBar
            onChange={Busqueda} 
            value={buscar} 
            placeholder="Buscar Entrega..."  
            data_bs_toggle="modal"
            data_bs_target="#exampleModal"
            onClick={AbrirIngreso}
            />
          </div>
          <div className='div-body'> 
<div className="div-table">
<div className="table-wrap">
  
<table className="table-item ">
  <thead >
          <tr>
          <th></th>
            <th>Nombre Del Empleado</th>
            <th>Cantidad</th>
            <th>Tipo de Entrega</th>
            <th>Comprobante</th>
            <th>Fecha</th>
            
            <th>Opciones</th>
          </tr>
        </thead>
       <tbody>
      { datos ?
           datos.map((item,index) =>(
            <tr key={index}>
               
               <td></td>
               <td>{item.empleado}</td>
               <td>{item.cantidad}</td>
               <td>{item.tipopago}</td>
               <td>{item.comprobante}</td>
               <td>{moment(item.fecha).format("DD/MM/YYYY")}</td>
               
               
               <td>
               <div className="d-flex dropdown justify-content-center alig-items-center">
  <i className="bi bi-three-dots " type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
   
  </i>
  <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton2">
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e)=>AbrirActualizar(item,e.target)} >Editar</li>
    <li  className="dropdown-item" onClick={()=>Eliminar(item.idplan)}>Eliminar</li>
      
   
  </ul>
</div>


                 </td>

             </tr>
           )) 
           : null
           
      
           }
      
       </tbody>
      </table>
      </div>

  
        </div>
          </div>
           
         
{/**modal para ingreso de empleado */}

  <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
        >
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Ingreso de Plan</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="form-outline mb-4">
         <label className="form-label" htmlFor="form1Example1" hidden= {true} >Codigo de entrega</label>   
    <input type="text" id="form1Example1" className="form-control" hidden= {true} value={identregado} onChange={(e) => setIdEntregado(e.target.value)} />
  </div>
  <div className="form-outline mb-4">
         <label className="form-label" htmlFor="form1Example1" hidden= {true} >Codigo de empleado</label>   
    <input type="text" id="form1Example1" className="form-control" hidden= {true} value={idempleado} onChange={(e) => setIdEmpleado(e.target.value)} />

  </div>
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Cantidad</label>
      <div className='input-group'>
          <span className="input-group-text">Q</span>
          <input type="text" id="form1Example1" className="form-control" value={cantidad}  onChange={(e) => setCantidad(e.target.value)} />
          <span className="input-group-text">.00</span>
      </div>
  </div>
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Tipo de entrega</label>
      <DropDown 
        dato = {ConvetirPagoAData(tipoPago)} 
        selected={tipo_entrega} 
        setSelected={setTipoEntrega}  
        value={buscarTipoPago}  
        setValue={setBuscarTipoPago} 
        onChange={BusquedaPago}
      />
  
   
      

  </div>

  
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example1" >Comprobante</label>
        <input type="text" id="form1Example1" className="form-control" value={comprobante}  onChange={(e) => setComprobante(e.target.value)} />

  </div>

  
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" className="btn btn-primary" onClick={()=>GuardarCambios()} >Guardar</button>
      </div>
    </div>
  </div>
</div>


     </div>   

    );
        }
    export default Reporte;
import host from "./Host"
import Header from "./Header";
import HandelError from "../utils/HandleError";

class Datos{
async Consulta(table) {
    try {
        const getData=await fetch(`${host+table}/view`,Header.headerGets());
        if(getData.status===200){
            return await getData.json();
        }
        HandelError(getData.status,table);
        return null;
    } catch (error) {
        HandelError(error.message,"");
        return null;
    }
}


 async  ConsutaID(table, id){
    try{
   const getDataItem=await  fetch(`${host+table}/view/${id}`,Header.headerGets());   
   if(getDataItem.status===200)  {
    return await getDataItem.json();
   }
   HandelError(getDataItem.status,table);
   return null;
    }catch(error){

        HandelError(error.message,"");  
        return null;
    }
     
    }
  async  ConsutaIDUser(table, id){
    try{
         const dataUser=await  fetch(`${host+table}/emp/${id}`,Header.headerGets());
          if(dataUser.status===200)  {
            return await dataUser.json();
           }
        HandelError(dataUser.status,table);
        return null;
     }catch(error){
         HandelError(error.message,"");  
         return null;
     }
      
     }

    async NuevoReg(table,datos){
        try{
            const insert=await fetch(`${host+table}`,Header.headerPostCB(datos));
            if(insert.status===200)  {
                return await insert.json();
               }
            HandelError(insert.status,table);
            return null;
        }catch(error){
            HandelError(error.message,"");  
            return null;
        }
         
        }
    async ActualizarReg(table,datos){
        try {
            const updateItem=await   fetch(`${host+table}/update`,Header.headerPostCB(datos));
            if(updateItem.status===200)  {
                return await updateItem.json();
               }
            HandelError(updateItem.status,table)
            return null;
        }catch(error){
            HandelError(error.message,"");  
            return null;
        }
         
        }
   async  BorrarReg(table, id){
    try{
        const itemDeleted=await fetch(`${host+table}/delete/${id}`,Header.headerGets());
        if(itemDeleted.status===200)  {
            return await itemDeleted.json();
           }
        HandelError(itemDeleted.status,table);
        return null;
    }catch(error){
        HandelError(error.message,"");  
        return null;
    }
     
    }
 async   ConsultaUser(data){
    try{
        const dataUser=await fetch(`${host}usuario/login`,Header.headerPostCBL(data));
        if(dataUser.status===200)  {
            return await dataUser.json();
           }
      //  HandelError(dataUser.status,"Usuario")
        return null
    }catch(error){
        HandelError(error.message,"");  
        return null;
    }
     
    }

   async ConsultaPermiso(datos){
    try{
        const dataAccess=await fetch(`${host}permiso/emp/${datos.idempleado}`,Header.headerGetsAccess(datos.token));
        if(dataAccess.status===200)  {
            return await dataAccess.json();
           }
        HandelError(dataAccess.status,"Permisos")
        return null;
    }catch(error){
        console.log(error)
        HandelError(error.message,"");  
        return null;
    }
     
    }
   
  async  ConsultaAbonoXP(id){
    try{
        const dataAbono=await fetch(`${host}abono/viewxp/${id}`,Header.headerGets());
        if(dataAbono.status===200)  {
            return await dataAbono.json();
           }
        HandelError(dataAbono.status,"Pagos")
        return null;
    }catch(error){
        HandelError(error.message,"");  
        return null;
    }
     
    }
    async  ConsultaAbonoPorEmpleado(id){
        try{
            const dataAbono=await fetch(`${host}abono/viewxemp/${id}`,Header.headerGets());
            if(dataAbono.status===200)  {
                return await dataAbono.json();
               }
            HandelError(dataAbono.status,"Pagos")
            return null;
        }catch(error){
            HandelError(error.message,"");  
            return null;
        }
         
        }

  async  UploadImg(datos){
  try {
    const formDatos=new FormData();
    formDatos.append('foo',datos);
    const imgUpload= await fetch(host+'img/upload',{
         method:"POST",
         body:formDatos
        });
    if(imgUpload.status===200)  {
        return true;
       }
    HandelError(imgUpload.status,"Imagen")
    return false;
    }catch(error){
        HandelError(error.message,"");  
        return false;
    }
     
    }

 async   ViewImg(name){
    try{
        const urlImg=await fetch(`${host}imgs/view/${name}`,{
            method: 'GET',
            mode:'cors',
            headers:{
              'Accept': 'application/json',
              "X-Content-Type-Options": "nosniff"

            },
           
          })
        return await urlImg.json();
        }catch(error){
            HandelError(error.message,"imagen");
        }
    }
      
    
 async DeleteImg(name){
    try {
        const imgDeleted=await fetch(`${host}img/delete/${name}`,{
            method: 'GET',
            headers:{
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
           
          });
        if(imgDeleted.status===200){
            return true
        }
        HandelError(imgDeleted.status, "imagen");
        return false;
    }catch(error){
        HandelError(error.message,"imagen");
        return false;
    }
    }
   
   async consultarInforme(tipo,datos){
    try{
        const dataInforme=await fetch(host+`${tipo}/`,Header.headerPostCB(datos));
        if(dataInforme.status===200){
            return await dataInforme.json();
        }
        HandelError(dataInforme.status,"informe");
        return null
    }catch(error){
        HandelError(error.message,"");
        return null;
    }
    }
     
      
     async ConsultaInfo(table){
        try{
            const dataInfo=await fetch(`${host+table}/view`,Header.headerGETCBI());
            if(dataInfo.status===200){
                return await dataInfo.json();
            }
            HandelError(dataInfo.status,"informe");
            return null;
        }catch(error){
            HandelError(error.message,"");
            return null;
        }
        }
}
export default new Datos();
import swal from "sweetalert";
 
export default function HandelError(error, table){
    const title="Credi Mex";
     switch(error){
        case 404:
           // swal(title, `No se encotraron registro `, "warning");
            break;
        case 500:
            swal(title, `Algo salio mal en el servidor, por revise y vuelva a intentar`,"error");
            break;
        default:
           // swal(title, `Algo salio mal, por revise su conexion y vuelva a intentar`,"error");
        break;
     }
}
import '../../styles/login.css';
import md5 from 'md5';
import React, { useContext, useEffect, useState } from 'react';
import Datos from '../../services/Datos';
import ls from "local-storage";
import swal from "sweetalert"
import Menu from '../Menu/Menu';
import { DataContext } from '../../context/Context';
import url from "../../assets/img/Logo.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../app/reducers/user/userSlice';
import { addGrantedToList } from '../../app/reducers/granted/grantedSlice';

function Login(props)  {
  const dispatch=useDispatch();
  const {isLogin}=useSelector(state=>state.user)
    const [user, setuser] = useState("");
    const [password, setpassword] = useState("");
   
    const [showPassword, setShowPassword] = useState(false)
    const [usuarioActual, setUsuarioActual]=useState([])
   

const dataUsuario=(codigo)=>{
  return {
       idusuario: codigo,
        idempleado: 0,
        usuario: user.trim(),
        pass: md5(password.trim()),
  }
}
    async function loguear(e) {
      e.preventDefault();
      let usuario = await Datos.ConsultaUser(dataUsuario(0));
    
      if (usuario !== null) {
          let datos = usuario.res[0];
           const ls=window.localStorage;
          ls.setItem("usuario",JSON.stringify( datos));   
            await SaveToStoreUser(datos)
         await consultarPermiso(datos);
       
          swal("PROMUJER", "Bienvenido", "success");
         limpiar()
         return
        } 
          swal("PROMUJER", "Usuario o Cantraseña Incorrecta", "warning");
          const ls=window.localStorage;
          ls.clear();
      
    }
    async function consultarPermiso(datos) {
     
      let dat = await Datos.ConsultaPermiso(datos);
       
      if (dat !== null) {
        if (dat.message === "Success") {
          dispatch(addGrantedToList(dat.res))
         return
        }
      }
    }
   

    const SaveToStoreUser = async(datos) => {
  console.log(datos)
      dispatch(setUser({
        idempleado:datos.idempleado,
        nombre:datos.nombre,
        apellido:datos.apellido,
        idusuario:datos.idusuario,
        token:datos.token,
        isLogin:datos.isLogin
      }))
      
    }
    
   
   function limpiar(){
    setUser("")
    setpassword("")
   }


return (
  <>

   <div className='d-flex justify-content-center align-items-center  vh-100 div-login'>

<form className=' p-5 rounded form-login'  onSubmit={(e)=>loguear(e)}>
<div className='d-flex justify-content-center align-items-center mb-4'>
<img src={url} alt="logo" width="60" height="60" className="rounded-circle"/>
</div>
<div className='d-flex justify-content-center align-items-center '>
<h4>Inicia sesión</h4>
</div>
<div className='form-outline mb-4'>

<p>Ingresar usuario y contraseña</p>
</div>
<label htmlFor="exampleFormControlInput1" className="form-label">Usuario</label>
<div className="input-group mb-3">
<span className='input-group-text '><i className="bi bi-person-fill"></i></span>
  <input 
  className="form-control"
            
              type="text"
              value={user}
              onChange={(e)=>setuser(e.target.value)}
         
            
              required={true}
              />
</div>




<div>
<label htmlFor="exampleFormControlInput1" className="form-label">Contraseña</label>
<div className="input-group mb-3">
  <span className='input-group-text '><i className="bi bi-lock-fill"></i></span>
  <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e)=>setpassword(e.target.value)}  required />
  <span className='input-group-text'>   <i className={showPassword ?"bi bi-eye-fill": "bi bi-eye-slash-fill"} onClick={()=>setShowPassword(!showPassword)}></i></span>


</div></div>
<div className="form-outline mb-4 d-flex justify-content-center align-items-center">

 
  <button type="submit" className="btn btn-primary btn-block mb-4 w-50">Ingresar</button>
  
</div>

</form>
    </div>
  </>
    
   


         
        );
    
}
export default Login;

/**
 * 
<div className="container-fluid login">
     
     <div className="container w-75 mt-5 mb-15">
         <div className="row bg  bg-opacity-50 align-items-stretch  rounded">
             <div className="col  d-none d-lg-block col-md-5 col-lg-5 col-xl-6  imgleft">
             </div>
             <div className="col  p-2 rounded-end">
                 <h2 className="fw-bold text-center pt-6 py-5  text-white"  >Bienvenido</h2>
                 
                 <div className="d-flex justify-content-center h-100 ">
                     <div> 
                         <div className="bg p-2 t bg-opacity-10">
                             <div className="input-group form-group">
                                 <div> 
                                     <label htmlFor="username" className="form-label text-white fw-bold" > Usuario: </label>
                                     <div className="input-group-prepend"> 
                                     <i className="bi bi-person form-control-icon "  ></i>
                                     <input type="text" className="form-control " placeholder="Username" name="user"  onChange={(e) => setuser(e.target.value)}/>
                                     </div>
                                     
                                 </div>
                             </div>
                               <div className="input-group form-group">
                                 <div className="mb-3">
                                     
                                         <label htmlFor="password" className="form-label text-white fw-bold">Contraseña:</label>
                                         <div className="form-group py-1 pb-2">
                                         <i className="bi bi-lock form-control-icon " ></i>  
                                         <input type="password" className="form-control" placeholder="Password" name="password" onKeyDown={(e)=>pressEnter(e)}   onChange={(e)=>setpassword(e.target.value)}/>
                                         </div>
                                 </div>
                                 
                             </div>                           
                             <div className="d-grid mb-10">
                                 <div>
                                     <input type="submit" value="Iniciar Sesión" className="btn btn-secondary w-100 my-100" onClick={()=> loguear()}/>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
             
 </div>
 */
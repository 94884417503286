import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Datos from '../../services/Datos';
import { addGrantedToList } from '../../app/reducers/granted/grantedSlice';
import { setUser } from '../../app/reducers/user/userSlice';
import Menu from './Menu';
import Login from '../Login/Login';

function Home() {
    const dispatch=useDispatch();
    const {isLogin}=useSelector(state=>state.user);
    
console.log(isLogin)
    useEffect(()=>{
        getInfo();
        IniciarApp(); 
    },[])

async function getInfo(){
    let info=await Datos.ConsultaInfo("ajuste")
    console.log(info)
}
async function getUsuarioPermiso(idempleado){
    const data= await Datos.ConsutaIDUser("permiso",idempleado);
    console.log(data)
    if(data !==null){
        if(data.message==="Success"){
                    dispatch(addGrantedToList(data.res))
        }

        
    }
}
async function IniciarApp(){
let usuario=JSON.parse(window.localStorage.getItem('usuario'));

if(usuario !== null){
    await getUsuarioPermiso(usuario.idempleado);
    dispatch(setUser({
        idempleado:usuario.idempleado,
        nombre:usuario.nombre,
        apellido:usuario.apellido,
        idusuario:usuario.idusuario,
        token:usuario.token,
        isLogin:usuario.isLogin
    }))
}
}
  return (
    <div className='Home'>{
        isLogin ?
        <Menu/>
        :
        <Login/>
    }
      
    </div> 
  )
}

export default Home


import Datos from "./Datos";


let Info={
    id:"",
    nombre:"",
    descripcion:"",
    direccion:"",
    mora:"",
    diasmora:"",
};

async function  ConsultarInfo (newDatos)  { 

    let datos=await Datos.ConsultaInfo("ajuste");
    if(datos!==null){
      
        newDatos.nombre=datos.res[0].nombre;
        newDatos.id=datos.res[0].id;
        newDatos.descripcion=datos.res[0].descripcion;
        newDatos.direccion=datos.res[0].direccion;
        newDatos.mora=datos.res[0].mora;
        newDatos.diasmora=datos.res[0].diasmora;

        //  newDatos.push(datos.res[0])
          
        
    }
 
    } 



ConsultarInfo(Info)



export {Info}
import React from 'react';
import ReactDOM from 'react-dom';

import './styles/sideBar.css'
import Login from './pages/Login/Login'
import store from './app/store';
import {Provider} from 'react-redux'
import Home from './pages/Menu/Home';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Home  />
    </Provider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import React, { useContext, useEffect, useState } from 'react';
import '../../styles/estilo.css'
import ls from "local-storage";
import {Info} from '../../services/Info';
import imgLogo from '../../assets/img/Logo.jpg'
import Plan from '../Plan/Plan';
import Empleado from '../Empleado/Empleado';
import AlertModel from './AlertModel';
import Cliente  from '../Cliente/Cliente';
import Cuenta from '../Cuenta/Cuenta';
import Abono  from '../Abono/Abono';
import Informe from '../Informe/Informe';

import Cobros from '../Abono/Cobros';
import Ajuste from './Ajuste';
import Usuario from '../Empleado/Usuario';
import Permisos from '../Empleado/Permisos';
import Reporte from '../Reporte/Reporte';
import CuentaPersonal from '../Cuenta/CuentaPersonal';
import SideBar from './SideBar';
import { useSelector } from 'react-redux';

function Menu(props)  {
    
 const {center}=useSelector(state=>state.center)

    const nav_item="nav-item";
    const sub_nav_item="sub_nav-item";
    const nav_active="nav-item nav-active";

   
    useEffect(() => {
       //iniciar();
    }, [])
  



/*
    function ColocarContent(){
      
         switch (screen) {
            case 'Ajuste':
                if(acceso("Ajuste")){
                    return <Ajuste />
                }else{
                    return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a los del sistema" />
                }
            case 'Cobros del Día':
                if(acceso("Cobros del Día")){
                    return <Cobros />
                }else{
                    return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a los cobros del dia" />
                }
           
                case 'Plan':
                    if(acceso("Plan")){
                        return <Plan />
                    }else{
                        return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Plan" />
                    }
               
                case 'Empleado':
                    if(acceso("Empleado")){
                        return <Empleado />
                    }else{
                        return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Empleado" />;
                    }
               
                case 'Cliente':
                    if(acceso("Cliente")){
                        return <Cliente />
                    }else{
                        return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Cliente" />;
                      }
                case 'Cuentas':
                    if(acceso("Cuentas")){
                         return <Cuenta />
                    }else{
                         return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Cuentas" />;
                    }
                    case 'Cuenta Personal':
                        if(acceso("Cuenta Personal")){
                             return <CuentaPersonal />
                        }else{
                             return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Cuenta Personal" />;
                        }
                case 'Abono':
                    if(acceso("Abono")){
                      return <Abono />
                   }else{
                      return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Abonos" />;
                   }
                   case 'Usuario':
                    if(acceso("Usuario")){
                      return <Usuario />
                   }else{
                      return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Usuarios" />;
                   }
                   case 'Permiso':
                    if(acceso("Permiso")){
                      return <Permisos />
                   }else{
                      return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Permisos" />;
                   }
                case 'Informe':
                    if(acceso("Informe")){
                      return <Informe />
                   }else{
                      return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a Informe" />;
                   }

                   case 'Reporte':
                    if(acceso("Reporte")){
                        return <Reporte />
                    }else{
                        return <AlertModel tipo="warning" titulo="Aviso" msg="No tienes acceso a los Reporte" />
                    }
               
                default:  
                return <AlertModel tipo="success" titulo="Credi Mex" msg="Bienvenido" />;
            }
           
     }*/
     function ColocarContent(){
      
        switch (center) {
           case 'Ajuste':
                   return <Ajuste />
           case 'Cobros del Día':
                   return <Cobros />
            case 'Plan':
                    return <Plan />
            case 'Empleado':
                    return <Empleado />
            case 'Cliente':
                 return <Cliente />
            case 'Cuenta General':
                    return <Cuenta />
            case 'Cuenta Personal':
                    return <CuentaPersonal />
            case 'Abono':
                return <Abono />
            case 'Usuario':
                return <Usuario />
            case 'Permiso':
                return <Permisos />
            case 'Informe':
                return <Informe />
             case 'Reporte':
                 return <Reporte />
              default:  
               return <AlertModel tipo="success" titulo="Credi Mex" msg="Bienvenido" />;
           }
          
    }
   
    return (

<div className="container-fluid vh-100">
    <SideBar/>
     <ColocarContent  /> 
  
    </div>
    
    );



}

export default Menu;

/**
 * 
 */

import React, { useState,useEffect } from 'react';
import md5 from "md5";
import swal from "sweetalert";
import SearchBar from '../../components/SearchBar';
import Datos from '../../services/Datos';
import urlImage from '../../services/urlImage';
import HeaderBar from '../Menu/HeaderBar';

function Empleado(props)  {
    const [idempleado, setIdempleado] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [dpi, setDpi] = useState(""); 
    const [telefono, setTelefono] = useState("");
    const [direccion, setDireccion] = useState("");
    const [prev_dpifront, setPrev_dpifront ]   =useState();
    const[prev_dpitra, setPrev_dpitra]=useState();
    const [estado, setEstado] = useState("Activo");
    const [dpi_frontal, setDpi_frontal] = useState("");
    const [dpi_trasera, setDpi_trasera] = useState(); 
    const [datos, setdatos] = useState([]);  
    const [encontrado, setencontrado] = useState([]);
    const [buscar, setbuscar] = useState("");
    const [accion, setAccion] = useState("new");
  
  
    const[datosPermiso, setDatosPermiso]=useState([]);
  const [modulo, setmodulo] = useState([]);
    const[empleadoSelected,setEmpeladoSelected]=useState([]);
    

    
    useEffect(()=>{
        ConsultarEmpleado();
       
      },[])
 
      const ConsultarEmpleado=async()=>{
     
        const datos=await Datos.Consulta("empleado");
      //datos  && datos.res && setdatos(datos.res); setencontrado(datos.res);
       if(datos!==null){      
        
          setdatos(datos.res);
          setencontrado(datos.res)
          return;
        }
        setdatos([]);
        setencontrado([]);
      }
      
  

      const SubirImagen =async (file) => { 
        const datosImg=await Datos.UploadImg(file);
        if(!datosImg) {
           swal("Aviso","No se pudo cargar la imagen","error");         
        }
       }
       
      
       
       const GetPreview = (file,setPreview) => { 
         let reader=new FileReader();
         reader.onloadend=function(e){
           setPreview(reader.result)
         }
         reader.readAsDataURL(file)
       }



      const limpiar=()=>{
        setIdempleado(0);
        setNombre("");
        setApellido("");
        setDpi("");
        setTelefono("");
        setDireccion("");
 
        setEstado("Activo");
      }

      const Ingresar=async()=>{
        let empleado=await Datos.NuevoReg("empleado",getEmployeeObject(0));
        if(empleado !== null){
          if(empleado.message==="Success"){
            swal("Empleado","Ingreso exitosamente","success");
          
        
          }else{
            swal("Empleado","No se pudo Ingresar, verifique los datos","warning");
          }
        }
      }

      const getEmployeeObject = (codigoEmpleado) => {
        return {
          idempleado:codigoEmpleado,
        nombre:nombre,
        apellido:apellido,
        dpi:dpi,
        telefono:telefono,
        direccion:direccion,
        dpi_adelante:"new",
        dpi_atras:"new",
        estado:estado
      }
      }
      
      const getEmployeeObjectImg = (codigoEmpleado) => {
        return {
          idempleado:codigoEmpleado,
        nombre:nombre,
        apellido:apellido,
        dpi:dpi,
        telefono:telefono,
        direccion:direccion,
        dpi_adelante:dpi_frontal.name,
        dpi_atras:dpi_trasera.name,
        estado:estado
      }
      }
      
      const Actualizar=async()=>{      
        let empleado=await Datos.ActualizarReg("empleado",getEmployeeObject(empleadoSelected.idempleado));
        if(empleado !== null){
          if(empleado.message==="Success"){
            swal("Empleado","Ingresdo exitosamente","success");
           
          }else{
            swal("Empleado","No se pudo Ingresar, verifique los datos","warning");
          }
        }
      }

      const Eliminar=async(id)=>{
        let empleado=await Datos.BorrarReg("empleado",id);
        if(empleado!==null){
          if(empleado.message === "Success"){
            swal("Plan", "Eliminado con exíto","success")
            ConsultarEmpleado();
          }else{
            swal("Plan","No se pudo eliminar","warning");
          }
        }
      }
      const DeletePreviousImage=async(imageName)=>{
        const imageDeleted=await Datos.DeleteImg(imageName);
        if(!imageDeleted){
          swal("Plan","No se pudo eliminar  la imagen","warning");
        }
      }
    
      

      const GuardarCambios= (e)=>{
        e.preventDefault();

        if(accion==="new"){
          try{
         //   const ffrontal=dpi_frontal;
           // const ftrasera=dpi_trasera;
              Ingresar();
             // SubirImagen(ffrontal)
              //SubirImagen(ftrasera)
              ConsultarEmpleado()
              limpiar();
          }catch(error){
          
          }
        
        }else{
    
          try{            
            //const ffrontal=dpi_frontal;
           // const ftrasera=dpi_trasera;
              Actualizar();
             /* if(ffrontal.name !== empleadoSelected.dpi_adelante){
               
                DeletePreviousImage(empleadoSelected.dpi_adelante);
                SubirImagen(ffrontal)
              }
              if(ftrasera.name !== empleadoSelected.dpi_atras){
                DeletePreviousImage(empleadoSelected.dpi_atras);
                SubirImagen(ftrasera)
              }*/
         
         
              ConsultarEmpleado()
              limpiar();
          }catch(error){
     
          }
        }
      }


      const AbrirActualizar=(datos,e)=>{
      
        setEmpeladoSelected(datos);
        setIdempleado(datos.idempleado);
        setNombre(datos.nombre);
        setApellido(datos.apellido);
        setDpi(datos.dpi);
        setTelefono(datos.telefono);
        setDireccion(datos.direccion);
       setDpi_frontal(datos.dpi_adelante);
       setDpi_trasera(datos.dpi_atras);
        setEstado(datos.estado)

      /*  setPrev_dpifront(urlImage(datos.dpi_adelante))
        setPrev_dpitra(urlImage(datos.dpi_atras))*/
       
        setAccion("update");
        
        var myInput = document.getElementById("exampleModal");
            e.addEventListener("shown.bs.modal", function () {
              myInput.focus();
            });
            }

            const Busqueda =(e)=>{
        
                let buscarTexto=e.target.value;
                setbuscar(buscarTexto);
                let text=buscarTexto.replace(/^\w/,(c) =>c.toLowerCase());
    
                
                setdatos(encontrado.filter(function(item){
                    return item.nombre.toLowerCase().includes(text) || item.apellido.toLowerCase().includes(text) ||  item.estado.toLowerCase().includes(text) ;   
                  }).map(function({idempleado, nombre, apellido, dpi, telefono, correo, estado}){
                    return{idempleado, nombre, apellido, dpi, telefono, correo, estado}
                  })
                 );
                
            }

                  const AbrirIngreso=(e)=>{
                    let myInput = document.getElementById("exampleModal");
                    e.target.addEventListener("shown.bs.modal", function () {
                      myInput.focus();
                    });
                    limpiar()
                  }

    const AbrirIngresoImagen=(item,e)=>{
    
      setEmpeladoSelected(item);
      setPrev_dpifront(urlImage(item.dpi_adelante));
      setPrev_dpitra(urlImage(item.dpi_atras))
                    let myInput = document.getElementById("exampleFoto");
                    e.target.addEventListener("shown.bs.modal", function () {
                      myInput.focus();
                    });
                  }

           const AgregarFotoCliente=async()=>{      
        let empleado=await Datos.ActualizarReg("empleadoimg",getEmployeeObjectImg(empleadoSelected.idempleado));
        if(empleado !== null){
          if(empleado.message==="Success"){
            swal("Empleado","Ingresado exitosamente","success");
           
          }else{
            swal("Empleado","No se pudo Ingresar, verifique los datos","warning");
          }
        }
      }
      
      const GuardarCambiosFotos= (e)=>{
        e.preventDefault();
          try{            
            const ffrontal=dpi_frontal;
            const ftrasera=dpi_trasera;
              AgregarFotoCliente();
              if(empleadoSelected.dpi_adelante ==="new"){
                SubirImagen(ffrontal)
              }else{    
               // if(ffrontal.name !== empleadoSelected.dpi_adelante){           
                DeletePreviousImage(empleadoSelected.dpi_adelante);
                SubirImagen(ffrontal)
              //}

              }
              if(empleadoSelected.dpi_atras==="new"){
                SubirImagen(ftrasera)
              }else{
                // if(ftrasera.name !== empleadoSelected.dpi_atras){
                DeletePreviousImage(empleadoSelected.dpi_atras);
                SubirImagen(ftrasera)
             // }
              }
          

          
             
         
         
              ConsultarEmpleado()
              limpiar();
          }catch(error){
       
          }
        
      }
              
    return(
       
        <div>
         <div className='div-header'>
          <HeaderBar  title="Empleado"  />
           
            <SearchBar
            onChange={Busqueda} 
            value={buscar} 
            placeholder="Buscar Empleado..."  
            data_bs_toggle="modal"
            data_bs_target="#exampleModal"
            onClick={AbrirIngreso}

            
            /> 
            

        </div> 
        <div className='div-body'>
         <div className="div-table">
<div className="table-wrap">
  
<table className="table-item ">
  <thead >
          <tr>
            <th></th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>DPI</th>
            <th>Direccion</th>
            <th>Telefono</th>
            <th>Estado</th>
            <th>Opciones</th>
          </tr>
        </thead>
       <tbody>
      { datos ?
           datos.map((item,index) =>(
            <tr key={index}>
               
               <td></td>
               <td>{item.nombre}</td>
               <td>{item.apellido}</td>
               <td>{item.dpi}</td>
               <td>{item.direccion}</td>
               <td>{item.telefono}</td>
            

               {item.estado === "Activo" ? <td ><p className="activo">{item.estado}</p></td>:
               <td ><p className="noactivo">{item.estado}</p></td>
                }
               
               <td>
               <div className="d-flex dropdown justify-content-center alig-items-center">
  <i className="bi bi-three-dots " type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
   
  </i>
  <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton2">
  <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e)=>AbrirActualizar(item,e.target)} >Editar</li>
    <li  className="dropdown-item" onClick={()=>Eliminar(item.idempleado)}>Eliminar</li>
    <li className=" dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleFoto" onClick={(e)=>AbrirIngresoImagen(item,e)} >Fotos del DPI</li>
  
  </ul>
</div>


                 </td>

             </tr>
           )) 
           : null
           
      
           }
      
       </tbody>
      </table>
      </div>

  
        </div>
        </div>
            
         
{/**modal para ingreso de empleado */}

  <form onSubmit={(e)=>{GuardarCambios(e)}} className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"  aria-hidden={true}  >
  
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Ingreso de Empleado</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
       
      <div className="modal-body">
  
      <div className="form-outline mb-4">
         <label className="form-label" htmlFor="form1Example1" hidden= {true} >Codigo de empleado</label>   
    <input type="text" id="form1Example1" className="form-control" hidden= {true} value={idempleado} onChange={(e) => setIdempleado(e.target.value)} />

     </div>
       <div className="form-outline mb-4">
     <label className="form-label" htmlFor="form1Example2" >Nombres</label>
     <input type="text" id="form1Example2" className="form-control" value={nombre}  onChange={(e) => setNombre(e.target.value)} required />
   
  </div>
  <div className="form-outline mb-4">
       <label className="form-label" htmlFor="form1Example3" >Apellidos</label>
        <input type="text" id="form1Example3" className="form-control" value={apellido}  onChange={(e) => setApellido(e.target.value)} required />

  </div>
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Numero de DPI</label>
  
        <input type="number"  id="form1Example1" className="form-control" value={dpi}  onChange={(e) => setDpi(e.target.value.slice(0,13))} required/>
      

  </div>

  <div className="form-outline mb-4">

       <label className="form-label" htmlFor="form1Example1" >Telefono</label>
        <input type="number" id="form1Example1" className="form-control" value={telefono}  onChange={(e) => setTelefono(e.target.value.slice(0,8))} required/>

  </div>
  
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Dirección</label>
   
          <input type="text" id="form1Example1" className="form-control" value={direccion}  onChange={(e) => setDireccion(e.target.value)} required />
         
  </div>
{/**
  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Foto del DPI frontal</label>
      <div className="custom-file">
        
    <input type="file" className="form-control"  id="formFile" aria-describedby="inputGroupFileAddon01" name={dpi_frontal} onChange={(e)=>{setDpi_frontal(e.target.files[0]); GetPreview(e.target.files[0],setPrev_dpifront)}} required/>
    <img src={prev_dpifront} alt="..." height={50} width={50}></img>
  </div>
  </div>


  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Foto del DPI trasera</label>
      <div className="custom-file">
        
    <input type="file" className="form-control"  id="formFile" aria-describedby="inputGroupFileAddon01" name={dpi_trasera} onChange={(e)=>{setDpi_trasera(e.target.files[0]); GetPreview(e.target.files[0],setPrev_dpitra)}} required/>
    <img src={prev_dpitra} alt="..." height={50} width={50}></img>
  </div>
  </div>

 */}
  

  
  <div className="form-outline mb-4 center">
       <label className="form-label" htmlFor="form1Example6">Estado</label>
       <div className="form-outline mb-4">
        <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value={estado} checked={estado === "Activo" ? true : false} onChange={() => setEstado("Activo")} />
  <label className="form-check-label" htmlFor="inlineRadio1">Activo</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value={estado} checked={estado === "No Activo" ? true : false} onChange={() => setEstado("No Activo")}/>
  <label className="form-check-label" htmlFor="inlineRadio2">No activo</label>
  </div>
</div>

 
     
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>        
        <input type="submit" className="btn btn-primary" value="Guardar" />
      </div>
     
       </div>
    </div>
      
  </div>

</form>

{/**final del modal para ingreso de empleado */}


{/**modal para administrar usuario del empledo */}

<form
onSubmit={(e)=>{GuardarCambiosFotos(e)}}
className="modal fade"
id="exampleFoto"
tabIndex="-1"
aria-labelledby="exampleModalLabel"
aria-hidden={true}
>
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title"> Fotos del DPI</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="form-outline mb-4">
         <label className="form-label" htmlFor="form1Example1" >Foto del DPI frontal</label>
        <div>
           <img src={prev_dpifront}  className='preview-image' alt=''></img>  
          </div> 
     
      <div className="custom-file">
        
    <input type="file" className="form-control btn-add-image"  id="formFile" aria-describedby="inputGroupFileAddon01" name={dpi_frontal} onChange={(e)=>{setDpi_frontal(e.target.files[0]); GetPreview(e.target.files[0],setPrev_dpifront)}} required/>

  </div>
  </div>


  <div className="form-outline mb-4">
      <label className="form-label" htmlFor="form1Example1" >Foto del DPI trasera</label>
      <div>
            <img src={prev_dpitra} alt="..." className='preview-image'></img>
      </div>
      <div className="custom-file">
        
    <input type="file" className="form-control btn-add-image"  id="formFile" aria-describedby="inputGroupFileAddon01" name={dpi_trasera} onChange={(e)=>{setDpi_trasera(e.target.files[0]); GetPreview(e.target.files[0],setPrev_dpitra)}} required/>

  </div>
  </div>
      </div>
      <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>        
        <input type="submit" className="btn btn-primary" value="Guardar" />
        
      </div>
    </div>
  </div>
</form>

{/**final del modal para administrar fotos del empleado */}

     </div>    

    );
        }
    export default Empleado;
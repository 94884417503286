import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unSetUser } from '../../app/reducers/user/userSlice';
import { setOpen } from '../../app/reducers/openMenu/openMenuSlice';
import { setCenter } from '../../app/reducers/center/centerSlice';
import logo from '../../assets/img/Logo.jpg'
import { removeGrantedToList } from '../../app/reducers/granted/grantedSlice';
import { unSetCenter } from '../../app/reducers/center/centerSlice';
const nav_item="op-item";

const nav_active="op-item op-active";



function SideBar(props) {
const dispatch =useDispatch();
const {open}=useSelector(state =>state.open)
const {center}=useSelector(state => state.center)
const {grantedList}=useSelector(state => state.granted)

console.log(grantedList)
const CerrarSesion = () => {
    window.localStorage.clear();
    dispatch(unSetUser())
    dispatch(removeGrantedToList())
    dispatch(unSetCenter())
    
  }
  const OpenMenu=()=>{
    dispatch(setOpen({
        open:!open
    }))
  }
  const SetCenter=(center)=>{
    dispatch(setCenter({
        center:center
    }))
    dispatch(setOpen({
        open:!open
    }))
  }

  /**
     
    
    
     
     
    
   */
  const OptionsMenu = () => {
 let Options=  grantedList.map((item,index)=>{
    if(acceso(item,"Cobros del Día") ){
    return <li onClick={()=>SetCenter("Cobros del Día")} key={index} className={center === "Cobros del Día" ? nav_active : nav_item}>
 
        <i className="bi bi-list-task"></i> <span className="ms-1  d-xl-inline">Cobros</span>
  
</li> 
}

if(acceso(item,"Plan") ){
  return<li onClick={()=>SetCenter("Plan")}  key={index}  className={center === "Plan" ? nav_active : nav_item}>
   
        <i className="bi bi-card-list"></i> <span className="ms-1  d-xl-inline">Plan</span>
    
</li>
}
if(acceso(item,"Empleado") ){
  return <li onClick={()=>SetCenter("Empleado")}  key={index}  className={center === "Empleado" ? nav_active : nav_item}>
    
        <i className="bi bi-file-earmark-person"></i> <span className="ms-1d-xl-inline">Empleado</span>

</li>}
if(acceso(item,"Cliente") ){
  return <li onClick={()=>SetCenter("Cliente")}  key={index}  className={center === "Cliente" ? nav_active : nav_item}>

        <i className="bi bi-person-lines-fill" aria-hidden="true"></i> <span className="ms-1  d-xl-inline">Cliente</span>

</li>}




if(acceso(item,"Cuenta General") ){
  return <li onClick={()=>SetCenter("Cuenta General")}  key={index} className={center === "Cuenta General" ? nav_active : nav_item}>

    <i className="bi bi-postcard" ></i> <span className="ms-1  d-xl-inline">Cuenta General</span>
  
</li>  }
if(acceso(item,"Cuenta Personal") ){
  return <li onClick={()=>SetCenter("Cuenta Personal")}  key={index} className={center === "Cuenta Personal" ? nav_active : nav_item}>
 
    <i className="bi bi-postcard" ></i> <span className="ms-1  d-xl-inline">Cuenta Personal</span>
  
</li>  }





{/**       <li onClick={()=>SetCenter("Abono")} className={center === "Abono" ? nav_active : nav_item}>
    <div    className="align-middle px-0">
    <i className="bi bi-wallet2" aria-hidden="true"></i> <span className="ms-1 d-none d-md-none d-xl-inline">Abono</span>
    </div>
</li>
*/}      
if(acceso(item,"Informe") ){
  return <li onClick={()=>SetCenter("Informe")} key={index}  className={center === "Informe" ? nav_active : nav_item}>

        <i className="bi bi-graph-up-arrow" ></i> <span className="ms-1  d-xl-inline">Informe</span>
     
</li>}
if(acceso(item,"Reporte") ){
  return <li onClick={()=>SetCenter("Reporte")} key={index}  className={center === "Reporte" ? nav_active : nav_item}>

        <i className="bi bi-card-checklist" ></i> <span className="ms-1  d-xl-inline">Reporte</span>
       
</li>}


if(acceso(item,"Usuario") ){
  return <li onClick={()=>SetCenter("Usuario")} key={index}  className={center === "Usuario" ? nav_active : nav_item}>

        <i className="bi bi-person-check" ></i> <span className="ms-1 d-xl-inline">Usuario</span>
     
</li>
}
if(acceso(item,"Permiso") ){
  return <li onClick={()=>SetCenter("Permiso")}  key={index} className={center === "Permiso" ? nav_active : nav_item}>

        <i className="bi bi-gear" ></i> <span className="ms-1 d-xl-inline">Acceso</span>
      
</li>
}

  }
  )
    return Options;
  }

const acceso = (item, modulo) => {
  if(item.nombre === modulo && item.permiso===1){
    return true;
  }
  return false;
}


  return (
    <div className={!open ? 'container-sidebar ': 'container-sidebar close'} onClick={OpenMenu} >
    <div className= "menu">
    <span className='iconclose' onClick={OpenMenu}><i className="bi bi-x" ></i></span>
   
    <div className='div-logo'> 
    <img  className='logo'src={logo} alt='logo'/>
    </div> 
    <div className='div-title'>
   <label className='title-sidebar'>PRO MUJER</label> 
    </div>
    <hr />
     <ul >
      <OptionsMenu/>
       <li  onClick={CerrarSesion} className='op-item'><i className="bi bi-box-arrow-left"></i><span>Log out</span></li>  
      </ul> 
      </div>
      </div>
  )
}

export default SideBar